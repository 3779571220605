import React, { useEffect, useState } from 'react';
import './hardmodify.css';

function Hardwaremodify({ setShowModel }) {
  const COOLDOWN_PERIOD = 30 * 60 * 60 * 1000; // Cooldown period in milliseconds (30 hours)
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const storedTimestamp = localStorage.getItem('formSubmittedTimestamp');
    if (storedTimestamp) {
      const lastSubmittedTime = parseInt(storedTimestamp, 10);
      const now = Date.now();

      if (now - lastSubmittedTime < COOLDOWN_PERIOD) {
        setFormSubmitted(true);
        setTimeRemaining(COOLDOWN_PERIOD - (now - lastSubmittedTime));
      } else {
        localStorage.removeItem('formSubmittedTimestamp');
      }
    }
  }, []);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          const newTime = prevTime - 1000;
          if (newTime <= 0) {
            clearInterval(timer);
            localStorage.removeItem('formSubmittedTimestamp');
            setFormSubmitted(false);
            return 0;
          }
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeRemaining]);

  const [formData, setFormData] = useState({
    Name: '',
    projectname: '',
    mobileNumber: '',
    gmail: '',
    preference: '',
    solutionStatement: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

      try {
        const response = await fetch('https://afot.in:5000/submit-form4', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error submitting form', error);
      }

    const timestamp = Date.now();
    localStorage.setItem('formSubmittedTimestamp', timestamp.toString());
    setFormSubmitted(true);
    setTimeRemaining(COOLDOWN_PERIOD);

    // Show the modal after a short delay
    const timeout = setTimeout(() => {
      setShowModel(true);
    }, 500); // 500 milliseconds delay

    return () => clearTimeout(timeout);
  };

  const handleDropdownChange = (e) => {
    setFormData({ ...formData, preference: e.target.value });
  };

  return (
    <div className="allpa">
      <div className="allharde">
        <div className="head-harde text-nowrap">PROJECT PROPOSAL FORM</div>
        <div className="container contharde">
          <div className="body-harde">
            {formSubmitted ? (
              <p>You have already submitted the form. Please try again later.</p>
            ) : (
              <form className="form" onSubmit={handleSubmit}>
                <div className="row harde-project">
                  <div className="form-group col-lg-2 col-md-2 col-1 harde">
                    <input className='inputhe' type="text" id='name' name="Name" value={formData.Name} onChange={handleChange} required />
                    <label className='labelhe' htmlFor="name">Name</label>
                  </div>
                  <div className="form-group col-lg-2 col-1 col-md-2 harde">
                    <input className='inputhe' type="text" id='projectname' name="projectname" value={formData.projectname} onChange={handleChange} required />
                    <label className='labelhe' htmlFor="projectname">Project Name</label>
                  </div>
                  <div className="form-group col-lg-2 col-1 col-md-2 harde">
                    <input className='inputhe' type="tel" id='num' name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} maxLength="10" pattern="[0-9]{10}" title="Please enter a valid mobile number (10 digits)" required />
                    <label className='labelhe text-nowrap' htmlFor="num">Mobile Number</label>
                  </div>
                  <div className="form-group col-lg-2 col-1 col-md-2 harde">
                    <input className='inputhe' type="email" id='gmail' name="gmail" value={formData.gmail} onChange={handleChange} required />
                    <label className='labelhe' htmlFor="gmail">Gmail</label>
                  </div>
                  <div className="form-group col-lg-2 col-1 col-md-2 harde">
                    <select className='selecthe' id='preference' name="preference" value={formData.preference} onChange={handleDropdownChange} required>
                      <option value="" disabled>Select Preference</option>
                      <option value="Option1">Option 1</option>
                      <option value="Option2">Option 2</option>
                      <option value="Option3">Option 3</option>
                    </select>
                  </div>
                  <div className="form-group textarea harde">
                    <textarea className='textareaharde' id="solutionStatement" cols="30" rows="10" name="solutionStatement" value={formData.solutionStatement} onChange={handleChange} required />
                    <label className='labelhtxt text-nowrap' htmlFor="solutionStatement">Solution Statement</label>
                  </div>
                  <div className="form-group button harde">
                    <button type='submit' className='btn btn-primary'>SUBMIT</button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hardwaremodify;

