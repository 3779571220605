import './photo.css'
import React from 'react'
import photo from '../about/about images/images/mukesh k.png'
function cardone() {
  return (
    <div className='photo-background'>
      <div className="row rowback">
        <div className="col-lg-6 col-sm-6 col-md-6">

        <div data-aos="fade-up-right" data-aos-easing="linear"
     data-aos-duration="1500">
        <img className='photo' src={photo} alt="" />
        </div>
          

        </div>
        <div  data-aos="fade-up-left" data-aos-easing="linear"
     data-aos-duration="1500" className="col-lg-6 col-sm-12 col-md-6">
         <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum ullam sit inventore odio error id, natus assumenda obcaecati placeat, in eum, ut dolore molestias ad nostrum doloremque labore! Vel, ullam.
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Commodi maxime a quis culpa, veniam vero vitae nam suscipit praesentium corporis sed pariatur, ipsum adipisci, sequi error saepe corrupti. Tenetur, eligendi!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat quam fuga iusto architecto placeat cum vitae id at porro. Eligendi illo ipsa aut mollitia quis explicabo earum, saepe eaque sapiente?
         </p>
        </div>
      </div>
    </div>
  )
}

export default cardone
