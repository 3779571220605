import React from 'react';
import './about.css'
import { Link } from 'react-router-dom';
import afot from '../about/about images/afot logo2.png'
import mukesh from '../about/about images/images/Mukesh3.jpg';
import rbd from '../about/about images/images/kishore RBt.png';
import gowthamm from '../about/about images/images/gowthamm.png';
import krishna from '../about/about images/images/krishann R.png';
import gowtham from '../about/about images/images/gowtham.png';
import mukunth from '../about/about images/images/Mukunth.png';
import gopi from '../about/about images/images/gopi.png';
import john from '../about/about images/images/john.png';
import griffin from '../about/about images/images/Griffin.jpg';
import kala from '../about/about images/images/Kala.jpg';
import { motion } from 'framer-motion';
export default function About() {
  return (

    <div className='about-back'>
            
	 
            
            <section className="about-page" >

            <div data-aos="fade-down-left" data-aos-duration="2000">

            <h1   className="hp">About Us</h1>
            </div>

            <div data-aos="fade-down-left" data-aos-duration="2500">
		
		<p className="hp2">
		All For One Tech is a Developing platform...
		</p>

    </div>


  <div className="about-info">
			<div class="about-img">
      <div data-aos="zoom-in-right" 
      data-aos-easing="linear"
      data-aos-duration="1500"
      >
      <img className='image555' src={afot} alt=""/>
      </div>
				
			</div>

      
      <div className='paragraph'  >


			<motion.p className="text-in-firstpage" 
      initial={{ opacity: 0, y: 100, x: 100 }}
      animate={{ opacity: 1, y: 0, x: 0 }}
      transition={{ duration: 1.5 }}
      
      > AFOT takes pride in its commitment to innovation, consistently pushing technological boundaries to deliver top-tier products and services to its valuable clients. Get in touch with us today to explore how AFOT can  assist you in real time goals. We're eagerly awaiting the opportunity to hear from you and help you unlock  your business's full potential with our cutting-edge software and hardware solutions. As a prominent company  in software and hardware specialization, we are excellent in crafting advanced solutions across various  platforms  and languages. Whether you're in need of bespoke software development, AI-driven applications, or advanced hardware solutions, AFOT has the expertise and dedication to meet your needs. Our team of skilled professionals is dedicated to designing innovative, tailored solutions that address your unique requirements  and challenges.
			</motion.p>
    
			
      </div>

     
      
			
		</div>
 

    
    
		
	</section>
            
	
  <div data-aos="zoom-out-right">

  <h1 className="head">Meet Our Team</h1>
  </div>

	
	<div className="cards">


  <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">




  <div class="card " style={{width:'18em'}} >
  <img src={mukesh} class="card-img-top" alt="..."/>


  <div class="card-body">
    <center><h5 class="card-title">mukesh k</h5></center>
    <p class="card-text"></p>
    <center><a class="btn btn-primary" href="https://mukeshk2335.github.io/MukeshK2335/">Known More</a></center>
  </div>
 
 
</div>
  </div>
    
  <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">

<div class="card"  style={{width:'18em'}} >
  <img src={rbd} class="card-img-top" alt="..."/>
  <div class="card-body">
    <center><h5 class="card-title">kishore RBt</h5></center>
    <p class="card-text">  </p>
    <center><a href="https://Kishorerbt03.github.io/Portfolio" class="btn btn-primary">Known More</a></center>
  </div>
</div>

</div>

<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
      <div class="card"  style={{width:'18em'}}>
  <img src={gowthamm} class="card-img-top" alt="..."/>
  <div class="card-body">
    <center><h5 class="card-title">Gowtham S</h5></center>
    <p class="card-text"></p>
    <center><a href="https://gowtham281.github.io/gowtham-portfolio/" class="btn btn-primary">Known More</a></center>
  </div>
</div>
</div>

<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">

      
<div class="card" style={{width:'18em'}} >
  <img src={griffin} class="card-img-top" alt="..."/>
  <div class="card-body">
    <center><h5 class="card-title">krishnan R</h5></center>
    <p class="card-text"></p>
    <center><a href="https://krishnan2005.github.io/portfolio/" class="btn btn-primary">Known More</a></center>
  </div>
</div>

</div>

<div data-aos="flip-left"

     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
                      
                      <div class="card" style={{width:'18em'}} >
  <img src={gowtham} class="card-img-top" alt="..."/>
  <div class="card-body">
    <center><h5 class="card-title">gowtham p</h5></center>
    <p class="card-text"></p>
   <center><a href="https://gowthammmmm.github.io/portfolio/" class="btn btn-primary">Known More</a></center>
  </div>
</div>
</div>

<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
      <div class="card" style={{width:'18em'}}>
  <img src={mukunth} class="card-img-top" alt="..."/>
  <div class="card-body">
    <center><h5 class="card-title">Mukunth J</h5></center>
    <p class="card-text"></p>
   <center> <a href="https://mukunth-j.github.io/" class="btn btn-primary">Known More</a></center>
  </div>
</div>
</div>

<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">

<div class="card"  style={{width:'18em'}}>
  <img src={gopi} class="card-img-top" alt="..."/>
  <div class="card-body">
    <center><h5 class="card-title">GOPINATH A</h5></center>
    <p class="card-text">
    </p>
   <center> <a href="https://gopiarumugam17.github.io/portfolio/" class="btn btn-primary">Known More</a></center>
  </div>
</div> 
</div>
	
<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
      <div class="card"  style={{width:'18em'}}>
  <img src={kala}class="card-img-top" alt="..."/>
  <div class="card-body">
   <center> <h5 class="card-title">Kalanithiselvan G</h5></center>
    <p class="card-text"></p>
   <center> <a href="#" class="btn btn-primary">Known More</a></center>
  </div>
</div> 
</div>

<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">

<div class="card" style={{width:'18em'}} >
  <img src={john} class="card-img-top" alt="..."/>
  <div class="card-body">
    <center><h5 class="card-title">John Neffimon J</h5></center>
    <p class="card-text"></p>
    <center><a href="#" class="btn btn-primary">Known More</a></center>
  </div>
</div> 

</div>








</div>

	 


	 

        </div>

  );
}

 
