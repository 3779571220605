import React, { useState,useEffect } from 'react';

function Softwaremodify() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (formSubmitted) {
      // Execute after form submission
      const timeout = setTimeout(() => {
        
      }, 100); // Change the timeout to 500 milliseconds (0.5 seconds)
  
      // Clear the timeout after 1 hour (3600000 milliseconds)
      const cooldownTimeout = setTimeout(() => {
        console.log('Cooldown timeout reached. Resetting formSubmitted to false.');
        setFormSubmitted(false);
      }, 3600000); // 1 hour in milliseconds
  
      return () => {
        clearTimeout(timeout);
        clearTimeout(cooldownTimeout);
      };
    }
  }, [formSubmitted]);
  const [formData, setFormData] = useState({
    Name: '',
    projectname: '',
    mobileNumber: '',
    gmail: '',
    preference: '',
    solutionStatement: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://afot.in:5000/submit-form5', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error submitting form', error);
    }

    setFormSubmitted(true);
  };

  const handleDropdownChange = (e) => {
    setFormData({ ...formData, preference: e.target.value });
  };

  return (
    
    <div className="allpa">
      <div className="allharde">
      <div className="head-harde text-nowrap">PROJECT PROPOSAL FORM</div>
      <div className="container contharde">
        <div className="body-harde">
          <form className="form" onSubmit={handleSubmit}>
            <div className="row harde-project">           
              <div className="form-group col-lg-2 col-md-2 col-1 harde">
                <input className=' inputhe' type="text" id='name' name="Name" value={formData.Name} onChange={handleChange} required />
                <label className=' labelhe' htmlFor="name">Name</label>
              </div>
              <div className="form-group col-lg-2 col-md-2 col-1 harde">
                <input className='inputhe' type="text" id='projectname' name="projectname" value={formData.projectname} onChange={handleChange} required />
                <label className=' labelhe' htmlFor="projectname">Project Name</label>
              </div>
              <div className="form-group col-lg-2 col-md-2 col-1 harde">
                <input className=' inputhe' type="tel" id='num' name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} maxLength="10" pattern="[0-9]{10}" title="Please enter a valid mobile number (10 digits)" required />
                <label className=' labelhe text-nowrap' htmlFor="num">Mobile Number</label>
              </div>
              <div className="form-group col-lg-2 col-md-2 col-1 harde">
                <input className=' inputhe' type="email" id='gmail' name="gmail" value={formData.gmail} onChange={handleChange} required />
                <label className=' labelhe' htmlFor="gmail">Gmail</label>
              </div>
              <div className="form-group col-lg-2 col-md-2 col-1 harde">
                <select className=' selecthe' id='preference' name="preference" value={formData.preference} onChange={handleDropdownChange} required>
                  <option value="" disabled> Preference</option>
                  <option value="php">PhP</option>
                  <option value="python">Python</option>
                  <option value="nodejs">Nodejs</option>
                  <option value="reactjs">Reactjs</option>
                  <option value="javascript">JavaScript</option>
                  <option value="flutter">Flutter</option>
                  <option value="linux">Linux</option>
                  <option value="mysql">Mysql</option>
                  <option value="machinelearning">Machine Learning</option>
                </select>
                
              </div>
              <div className="form-group textarea harde">
                <textarea className=' textareaharde' id="solutionStatement" cols="30" rows="10" name="solutionStatement" value={formData.solutionStatement} onChange={handleChange} required />
                <label className='labelhtxt  text-nowrap' htmlFor="solutionStatement">Solution Statement</label>
              </div>
              <div className="form-group button harde">
                <button type='submit' className='btn btn-primary'>SUBMIT</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
    
  );
}

export default Softwaremodify