import React, { useState, useEffect } from 'react';
import './school.css';

const COOLDOWN_PERIOD = 1800000; // 30 minutes in milliseconds

function College({ setShowModel }) {
  const [formData, setFormData] = useState({
    collegename: '',
    projectname: '',
    number: '',
    email: '',
    preference: '',
    whatshoulddo: '',
    librarymanagement: '',
    attendancemanagement: '',
    idnfcmanagement: '',
    busmanagement: ''
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isFormVisible, setIsFormVisible] = useState(true);

  useEffect(() => {
    const storedTimestamp = localStorage.getItem('formSubmittedTimestamp');
    if (storedTimestamp) {
      const lastSubmittedTime = parseInt(storedTimestamp, 10);
      const now = Date.now();

      if (now - lastSubmittedTime < COOLDOWN_PERIOD) {
        setFormSubmitted(true);
        setIsFormVisible(false);
        setTimeRemaining(COOLDOWN_PERIOD - (now - lastSubmittedTime));

        const countdownInterval = setInterval(() => {
          const now = Date.now();
          const remaining = COOLDOWN_PERIOD - (now - lastSubmittedTime);
          if (remaining <= 0) {
            clearInterval(countdownInterval);
            setFormSubmitted(false);
            setIsFormVisible(true);
            localStorage.removeItem('formSubmittedTimestamp');
          } else {
            setTimeRemaining(remaining);
          }
        }, 1000); // Update every second

        return () => clearInterval(countdownInterval);
      } else {
        localStorage.removeItem('formSubmittedTimestamp');
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdownChange = (e) => {
    setFormData({ ...formData, preference: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://afot.in:5000/submit-college-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error submitting form', error);
    }
    if (formSubmitted) {
      // Do nothing if the form is still in cooldown
      return;
    }
    setFormSubmitted(true);
    setShowModel(true);

    const now = Date.now();
    localStorage.setItem('formSubmittedTimestamp', now.toString());
    setIsFormVisible(false);
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.ceil(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <div className="allpa">
      <div className="allschool">
        <div className="head-school text-nowrap">PROJECT PROPOSAL FORM</div>
        <div className="container contschool">
          <div className="body-school">
            {formSubmitted && timeRemaining > 0 && (
              <div className="cooldown-message">
                Please wait {formatTime(timeRemaining)} before submitting again.
              </div>
            )}
            {isFormVisible && !formSubmitted && (
              <form className="form" onSubmit={handleSubmit}>
                <div className="row school-project">
                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 school">
                    <input
                      className='inputsc'
                      type="text"
                      id='name'
                      name="collegename"
                      value={formData.collegename}
                      onChange={handleChange}
                      required
                    />
                    <label className='labelsc' htmlFor="name">College Name</label>
                  </div>
                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 school">
                    <input
                      className='inputsc'
                      type="text"
                      id='projectname'
                      name="projectname"
                      value={formData.projectname}
                      onChange={handleChange}
                      required
                    />
                    <label className='labelsc' htmlFor="projectname">Project Name</label>
                  </div>
                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 school">
                    <input
                      className='inputsc'
                      type="tel"
                      id='num'
                      name="number"
                      value={formData.number}
                      onChange={handleChange}
                      maxLength="10"
                      pattern="[0-9]{10}"
                      title="Please enter a valid mobile number (10 digits)"
                      required
                    />
                    <label className='labelsc text-nowrap' htmlFor="num">Mobile Number</label>
                  </div>
                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 school">
                    <input
                      className='inputsc'
                      type="email"
                      id='gmail'
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <label className='labelsc' htmlFor="gmail">Gmail</label>
                  </div>
                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 school">
                    <select
                      className='selectsc'
                      id='preference'
                      name="preference"
                      value={formData.preference}
                      onChange={handleDropdownChange}
                      required
                    >
                      <option value="" disabled>Select Preference</option>
                      <option value="librarymanagement">Library Management</option>
                      <option value="attendancemanagement">Attendance Management</option>
                      <option value="idnfcmanagement">IDnfc Management</option>
                      <option value="busmanagement">Bus Management</option>
                    </select>
                  </div>
                  <div className="form-group textarea school">
                    <textarea
                      className="textareaschool"
                      id="what"
                      cols="30"
                      rows="10"
                      name="whatshoulddo"
                      value={formData.whatshoulddo}
                      onChange={handleChange}
                      required
                    />
                    <label className="labelstxt text-nowrap" htmlFor="what">What should we do?</label>
                  </div>
                  <div className="form-group button school">
                    <button
                      type='submit'
                      className='btn btn-primary'
                      disabled={formSubmitted && timeRemaining > 0}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default College;

