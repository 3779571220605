import React, { useEffect, useState } from 'react';
import './paper.css';

function Paper({ setShowModel }) {
  const COOLDOWN_PERIOD = 30 * 60 * 60 * 1000; // Cooldown period in milliseconds (1 hour)
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const storedTimestamp = localStorage.getItem('formSubmittedTimestamp');
    if (storedTimestamp) {
      const lastSubmittedTime = parseInt(storedTimestamp, 10);
      const now = Date.now();

      if (now - lastSubmittedTime < COOLDOWN_PERIOD) {
        setFormSubmitted(true);
        setTimeRemaining(COOLDOWN_PERIOD - (now - lastSubmittedTime));
      } else {
        localStorage.removeItem('formSubmittedTimestamp');
      }
    }
  }, []);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          const newTime = prevTime - 1000;
          if (newTime <= 0) {
            clearInterval(timer);
            localStorage.removeItem('formSubmittedTimestamp');
            setFormSubmitted(false);
            return 0;
          }
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeRemaining]);

  const [formData, setFormData] = useState({
    teamName: '',
    leaderName: '',
    paperName: '',
    mobileNumber: '',
    gmail: '',
    groupOrSolo: '',
    solutionStatement: '',
    whatToDo: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://afot.in:5000/submit-form2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error submitting form', error);
    }

    const timestamp = Date.now();
    localStorage.setItem('formSubmittedTimestamp', timestamp.toString());
    setFormSubmitted(true);
    setTimeRemaining(COOLDOWN_PERIOD);

    // Show the modal after a short delay
    const timeout = setTimeout(() => {
      setShowModel(true);
    }, 500); // 500 milliseconds delay

    return () => clearTimeout(timeout);
  };

  return (
    <div className="allpa">
      <div className="allpaper">
        <div className="head-paper text-nowrap">PAPER WORK FORM</div>
        <div className="container contpaper">
          <div className="body-paper">
            {formSubmitted ? (
              <p>You have already submitted the form. Please try again later.</p>
            ) : (
              <form className="form" onSubmit={handleSubmit}>
                <div className="row paper-project">
                  <div className="form-group col-sm-1 col-md-2 col-1 paper">
                    <input className='inputfi' type="text" id='tname' name="teamName" value={formData.teamName} onChange={handleChange} required />
                    <label className='labelfi' htmlFor="tname">Team Name</label>
                  </div>
                  <div className="form-group col-sm-1 col-md-2 col-1 paper">
                    <input className='inputfi' type="text" id='lname' name="leaderName" value={formData.leaderName} onChange={handleChange} required />
                    <label className='labelfi' htmlFor="lname">Leader Name</label>
                  </div>
                  <div className="form-group col-sm-1 col-md-2 col-1 paper">
                    <input className='inputfi' type="tel" id='num' name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} maxLength="10" pattern="[0-9]{1,10}" title="Please enter a valid mobile number (up to 10 digits)" required />
                    <label className='labelfi text-nowrap' htmlFor="num">Mobile Number</label>
                  </div>
                  <div className="form-group col-sm-1 col-md-2 col-1 paper">
                    <input className='inputfi' type="email" id='ename' name="gmail" value={formData.gmail} onChange={handleChange} required />
                    <label className='labelfi' htmlFor="ename">Gmail</label>
                  </div>
                  <div className="form-group col-sm-1 col-md-2 col-1 paper">
                    <input className='inputfi' type="text" id='pname' name="paperName" value={formData.paperName} onChange={handleChange} required />
                    <label className='labelfi' htmlFor="pname">Paper Name</label>
                  </div>
                  <div className="form-group col-sm-2 col-md-2 col-1 paper">
                    <input className='gbutton ' type="radio" id='rbutton' name="groupOrSolo" value="group" onChange={handleChange} />
                    <label className='radio-button' htmlFor="rbutton">Group</label>
                  </div>
                  <div className="form-group col-sm-2 col-md-2 col-1 paper">
                    <input className='rbutton' type="radio" id='gbutton' name="groupOrSolo" value="solo" onChange={handleChange} />
                    <label className='radio-rbutton' htmlFor="gbutton">Solo</label>
                  </div>
                  <div className="form-group textarea paper">
                    <textarea className='textarea-project' id="taname" cols="30" rows="10" name="solutionStatement" value={formData.solutionStatement} onChange={handleChange} required />
                    <label className='labelftxt text-nowrap' htmlFor="taname">Solution Statement</label>
                  </div>
                  <div className="form-group textarea paper">
                    <textarea className='textarea-project' id="saname" cols="30" rows="10" name="whatToDo" value={formData.whatToDo} onChange={handleChange} required />
                    <label className='labelftxt text-nowrap' htmlFor="saname">What Should We Do</label>
                  </div>
                  <div className="form-group button paper">
                    <button type='submit'>SUBMIT</button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Paper;

