import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const FreelanceViewPage = () => {
  const { title } = useParams();
  const [project, setProject] = useState(null);
  const [error, setError] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: ''
  });

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`https://afot.in:5001/api/viewdetail/${title}`);
        setProject(response.data.project);
      } catch (err) {
        setError('No project found');
      }
    };
    fetchProject();
  }, [title]);

  const loadRazorpay = async () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const handlePayment = async (amount) => {
    try {
      await loadRazorpay();
      if (!window.Razorpay) {
        alert('Razorpay SDK is not loaded');
        return;
      }

      const response = await axios.post('https://afot.in:5001/api/freelance/payment/order', { amount });
      const { id, currency } = response.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amount,
        currency: currency,
        name: project.title,
        description: project.projectdetail,
        order_id: id,
        handler: async function (response) {
          alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
          // Download the zip file
          const zipFileUrl = `https://afot.in:5001/${project.zipfile}`;
          const link = document.createElement('a');
          link.href = zipFileUrl;
          link.setAttribute('download', 'project.zip');
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.contact
        },
        notes: {
          address: 'Customer Address'
        },
        theme: {
          color: '#3399cc'
        }
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setShowForm(false);
    handlePayment(formData.amount);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!project) {
    return <div>Loading...</div>;
  }

  const getImageUrl = (imagePath) => {
    return `https://afot.in:5001/${imagePath}`;
  };

  return (
    <div className='main-buy'>
      <div className="slider-containers">
        <div className="slide-imgs">
          {project.images && project.images.length > 0 && project.images.map((imagePath, index) => (
            <img
              key={index}
              src={getImageUrl(imagePath)}
              width="50"
              height="60"
              alt={project.title}
              className="slider-images"
              onError={(e) => e.target.style.display = 'none'} // Hide broken images
            />
          ))}
        </div>
      </div>

      <div className="buy-contents">
        <h1>Title: {project.title}</h1>
        <p>Domain: {project.domainname}</p>
        <p className='para444'>{project.projectdetail}</p>
        <button onClick={() => setShowForm(true)}>Proceed to Payment</button>
      </div>

      {showForm && (
        <form onSubmit={handleFormSubmit} className="payment-form">
          <h2>Payment Details</h2>
          <label>
            Name:
            <input type="text" name="name" value={formData.name} onChange={handleInputChange} required />
          </label>
          <label>
            Email:
            <input type="email" name="email" value={formData.email} onChange={handleInputChange} required />
          </label>
          <label>
            Contact:
            <input type="text" name="contact" value={formData.contact} onChange={handleInputChange} required />
          </label>
          <label>
            Amount:
            <select name="amount" value={formData.amount} onChange={handleInputChange} required>
              <option value={project.minprice}>₹{project.minprice}</option>
              <option value={project.maxprice}>₹{project.maxprice}</option>
            </select>
          </label>
          <button type="submit">Pay</button>
        </form>
      )}
    </div>
  );
};

export default FreelanceViewPage;
