import React, { useState, useEffect } from 'react';
import './selling.css';
import RangeSlider from 'react-slider';
import LoginPopup from './login-popup.js'; 

const Selling = ({ setShowModel }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPopup, setShowPopup] = useState(true); // Show popup by default
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status

  useEffect(() => {
    if (formSubmitted) {
      const timeout = setTimeout(() => {
        setShowModel(true);
      }, 100); // Brief delay before showing model

      const cooldownTimeout = setTimeout(() => {
        console.log('Cooldown timeout reached. Resetting formSubmitted to false.');
        setFormSubmitted(false);
      }, 3600000); // 1 hour cooldown

      return () => {
        clearTimeout(timeout);
        clearTimeout(cooldownTimeout);
      };
    }
  }, [formSubmitted, setShowModel]);

  const [formData, setFormData] = useState({
    Name: '',
    projectname: '',
    mobileNumber: '',
    gmail: '',
    price: '',
    message: ''
  });

  const [zipFiles, setZipFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 50000]); // Default range

  const [tempMin, setTempMin] = useState(''); // Initialize as empty string
  const [tempMax, setTempMax] = useState(''); // Initialize as empty string

  const handleRangeChange = (value) => {
    setPriceRange(value);
    setTempMin(value[0] === 0 ? '' : value[0]); // Set to empty string if default value
    setTempMax(value[1] === 50000 ? '' : value[1]); // Set to empty string if default value
  };

  const updateMin = (e) => {
    const newMin = parseInt(e.target.value, 10);
    if (!isNaN(newMin) && newMin <= priceRange[1]) {
      setTempMin(newMin);
      setPriceRange([newMin, priceRange[1]]);
    } else {
      setTempMin('');
    }
  };

  const updateMax = (e) => {
    const newMax = parseInt(e.target.value, 10);
    if (!isNaN(newMax) && newMax >= priceRange[0]) {
      setTempMax(newMax);
      setPriceRange([priceRange[0], newMax]);
    } else {
      setTempMax('');
    }
  };

  const handleBlurMin = () => {
    if (tempMin === '') {
      setPriceRange([0, priceRange[1]]);
    } else if (tempMin < 0) {
      setTempMin('');
      setPriceRange([0, priceRange[1]]);
    }
  };

  const handleBlurMax = () => {
    if (tempMax === '') {
      setPriceRange([priceRange[0], 50000]);
    } else if (tempMax > 50000) {
      setTempMax('');
      setPriceRange([priceRange[0], 50000]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleZipChange = (e) => {
    const files = Array.from(e.target.files).filter(file => file.name.endsWith('.zip'));
    if (files.length !== Array.from(e.target.files).length) {
      alert('Some files are not ZIP files and will not be uploaded.');
    }
    setZipFiles([...zipFiles, ...files]);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files).filter(file => {
      if (file.type.startsWith('image/') && file.size <= 10 * 1024 * 1024) {
        return true;
      } else {
        alert(`${file.name} is not a valid image file or exceeds the 10MB size limit.`);
        return false;
      }
    });
    setImageFiles([...imageFiles, ...files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      alert('You must be logged in to submit the form.');
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append('Name', formData.Name);
    formDataToSend.append('projectname', formData.projectname);
    formDataToSend.append('mobileNumber', formData.mobileNumber);
    formDataToSend.append('gmail', formData.gmail);
    formDataToSend.append('minPrice', priceRange[0]); // Use priceRange min
    formDataToSend.append('maxPrice', priceRange[1]); // Use priceRange max
    formDataToSend.append('message', formData.message);
  
    zipFiles.forEach((file) => {
      formDataToSend.append('zipFiles', file);
    });
  
    imageFiles.forEach((file) => {
      formDataToSend.append('imageFiles', file);
    });

    try {
      const response = await fetch('https://afot.in:5000/submit-selling-form', {
        method: 'POST',
        body: formDataToSend,
      });
  
      const data = await response.json();
      console.log(data);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  const handleDeleteZip = (index) => {
    setZipFiles(zipFiles.filter((_, i) => i !== index));
  };

  const handleDeleteImage = (index) => {
    setImageFiles(imageFiles.filter((_, i) => i !== index));
  };

  const handleFormSubmit = () => {
    setIsLoggedIn(true);
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };  

  return (
    <div className="allsell">
      <div className="head-sell text-nowrap">PROJECT SELLING FORM</div>
      <div className="container contsell">
        <div className="body-sell">
          {isLoggedIn ? (
            <form onSubmit={handleSubmit}>
              <div className="row sell-project">
                <div className="form-group col-lg-3 col-sm-2 col-md-3 col-2 sell">
                  <input
                    className='input-sell'
                    type="text"
                    id="Name"
                    name="Name"
                    value={formData.Name}
                    onChange={handleChange}
                    required
                  />
                  <label className='label-sell' htmlFor="Name">Name</label>
                </div>
                <div className="form-group col-lg-3 col-sm-3 col-md-3 col-3 sell">
                  <input
                    className='input-sell'
                    type="text"
                    id="projectname"
                    name="projectname"
                    value={formData.projectname}
                    onChange={handleChange}
                    required
                  />
                  <label className='label-sell' htmlFor="projectname">Project Name</label>
                </div>
                <div className="form-group col-lg-3 col-sm-2 col-md-3 col-2 sell">
                  <input
                    className='input-sell'
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    required
                  />
                  <label className='label-sell' htmlFor="mobileNumber">Mobile Number</label>
                </div>
                <div className="form-group col-lg-3 col-sm-2 col-md-3 col-2 sell">
                  <input
                    className='input-sell'
                    type="email"
                    id="gmail"
                    name="gmail"
                    value={formData.gmail}
                    onChange={handleChange}
                    required
                  />
                  <label className='label-sell' htmlFor="gmail">Email</label>
                </div>
              </div>

              <div className="row sell-project">
                <div className="form-group col-lg-4 col-sm-3 col-md-4 col-4 sell">
                  <input
                    className='input-sell'
                    type="number"
                    id="minPrice"
                    name="minPrice"
                    value={tempMin}
                    onChange={updateMin}
                    onBlur={handleBlurMin}
                    placeholder="Min Price"
                  />
                </div>
                <div className="form-group col-lg-4 col-sm-3 col-md-4 col-4 sell">
                  <input
                    className='input-sell'
                    type="number"
                    id="maxPrice"
                    name="maxPrice"
                    value={tempMax}
                    onChange={updateMax}
                    onBlur={handleBlurMax}
                    placeholder="Max Price"
                  />
                </div>
                <div className="form-group col-lg-4 col-sm-3 col-md-4 col-4 sell">
                  <RangeSlider
                    value={priceRange}
                    onChange={handleRangeChange}
                    min={0}
                    max={50000}
                  />
                </div>
              </div>

              <div className="row sell-project">
                <div className="form-group col-lg-4 col-sm-4 col-md-4 col-4 sell">
                  <input
                    className='input-sell'
                    type="file"
                    accept=".zip"
                    multiple
                    onChange={handleZipChange}
                  />
                  <div className='file-list'>
                    {zipFiles.map((file, index) => (
                      <div key={index} className='file-item'>
                        {file.name}
                        <button type="button" onClick={() => handleDeleteZip(index)}>Delete</button>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="form-group col-lg-4 col-sm-4 col-md-4 col-4 sell">
                  <input
                    className='input-sell'
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageChange}
                  />
                  <div className='file-list'>
                    {imageFiles.map((file, index) => (
                      <div key={index} className='file-item'>
                        {file.name}
                        <button type="button" onClick={() => handleDeleteImage(index)}>Delete</button>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="form-group col-lg-4 col-sm-4 col-md-4 col-4 sell">
                  <textarea
                    className='textarea-sell'
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Message"
                  />
                </div>
              </div>

              <button type="submit" className='submit-btn'>Submit</button>
            </form>
          ) : (
            showPopup && <LoginPopup handleFormSubmit={handleFormSubmit} handleClose={handleClosePopup} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Selling;
