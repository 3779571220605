
import { Link } from 'react-router-dom' 
import './Navbar.css'
import logo from '../image/AFOT.png'
import menu from '../image/menu (1) (1).png'
import { Nav,NavDropdown,Navbar,Container } from 'react-bootstrap';
import { useContext, useState } from "react";
import afotlogo2 from '../image/afot logo2.png'
import { CountContext } from "../App.js";
import light from '../image/sun (2).png'
import dark from '../image/moon.png'
import '../App.css';


const  NavbarBootstrap = () => {

  const { darkTheme,toggleTheme } = useContext(CountContext);
  const [down,setDown]=useState(false);

  const toogle = (e) =>{

    setDown(!down);

  }
  const tooglehide = (e) =>{

    setDown(false);

  }

  return (

    
  
  <body >
    
 
    <Navbar className='navbar' expand='md'>
  
    <Container fluid className='c' >
    <Nav.Link as={Link} to='/'><img className='afortlogo' src={afotlogo2} alt="" /></Nav.Link>  
    <Navbar.Brand> <Nav.Link as={Link} to='/'><img className='pic4' src={logo} alt="" /></Nav.Link></Navbar.Brand>
   
      <Navbar.Toggle className='pic8'  aria-controls="navbar-brand"><img src={menu} alt="" /></Navbar.Toggle>
      
      <Navbar.Collapse id="navbar-brand">
         
      <Nav className="  ms-auto  my-lg-6 mx-5 navi fs-4 "
        style={{ maxHeight: '1000px', 
        px:'5',}}
        navbar >  
                                                                                             
        <Nav.Link className='link4' as={Link} to="/about" >About</Nav.Link> 
        <Nav.Link className='link4' as={Link} to='/contact'>Contact</Nav.Link>
      
         <NavDropdown className='link4 '  title="Freelance"
         show={down}
         onMouseLeave={tooglehide}
         onMouseEnter={toogle}>
         <NavDropdown.Item className='link1 ' as={Link} to='/sell'>Sell Your Project</NavDropdown.Item>
         <NavDropdown.Item className='link1' as={Link} to='/buy'>Buy Another Project</NavDropdown.Item>
         </NavDropdown>
         {/* <Nav.Link className='link4' as={Link} to="/about">about</Nav.Link> */}
         <Nav.Link className='link4' as={Link} to='/products'>Products </Nav.Link>
        
        
        
        
      </Nav> 
      </Navbar.Collapse>
      

    </Container>
    <div className='position-relative' > <nav className='bbd position-absolute top-0 end-0'onClick={toggleTheme}><img src={darkTheme ? light:dark} alt="" /></nav> </div> 
  </Navbar>

</body> 
  


  )
}

export default NavbarBootstrap