import React, { useState } from 'react';
import axios from 'axios';
import './adminpanel.css'; // Importing the CSS file

const AdminPanel = () => {
  const [formData, setFormData] = useState({
    title: '',
    sellerName: '',
    domainName: '',
    minPrice: '',
    maxPrice: '',
    zipFile: null,
    images: [],
    projectDetail: ''
  });

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'images') {
      setFormData(prevState => ({
        ...prevState,
        images: Array.from(files)
      }));
    } else if (name === 'zipFile') {
      setFormData(prevState => ({
        ...prevState,
        zipFile: files[0]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleImageRemove = (index) => {
    setFormData(prevState => ({
      ...prevState,
      images: prevState.images.filter((_, i) => i !== index)
    }));
  };

  const handleZipFileRemove = () => {
    setFormData(prevState => ({
      ...prevState,
      zipFile: null
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('title', formData.title);
      formDataToSend.append('sellerName', formData.sellerName);
      formDataToSend.append('domainName', formData.domainName);
      formDataToSend.append('minPrice', formData.minPrice);
      formDataToSend.append('maxPrice', formData.maxPrice);
      formDataToSend.append('projectDetail', formData.projectDetail);
      if (formData.zipFile) {
        formDataToSend.append('zipFile', formData.zipFile);
      }
      formData.images.forEach(image => {
        formDataToSend.append('images', image);
      });

      const response = await axios.post('https://afot.in:5001/api/freelance', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 201) {
        console.log('Project added successfully');
        setFormData({
          title: '',
          sellerName: '',
          domainName: '',
          minPrice: '',
          maxPrice: '',
          zipFile: null,
          images: [],
          projectDetail: ''
        });
      } else {
        console.error('Error adding project');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://afot.in:5001/api/login', {
        username,
        password
      });

      if (response.status === 200 && response.data.isAuthenticated) {
        setIsAuthenticated(true);
      } else {
        alert('Invalid username or password');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('Login failed. Please try again.');
    }
  };

  return (
    <div className="admin-container">
      {!isAuthenticated ? (
        <div className="admin-login-wrapper">
          <form onSubmit={handleLogin}>
            <div className="admin-form-row">
              <label>Username:</label>
              <input
                type="text"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="admin-form-row">
              <label>Password:</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="admin-form-row">
              <button type="submit">Login</button>
            </div>
          </form>
        </div>
      ) : (
        <div className="admin-form-wrapper">
          <form onSubmit={handleSubmit}>
            <div className="admin-form-row">
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Title"
                required
              />
            </div>
            <div className="admin-form-row">
              <label>Seller Name:</label>
              <input
                type="text"
                name="sellerName"
                value={formData.sellerName}
                onChange={handleChange}
                placeholder="Seller Name"
                required
              />
            </div>
            <div className="admin-form-row">
              <label>Domain Name:</label>
              <input
                type="text"
                name="domainName"
                value={formData.domainName}
                onChange={handleChange}
                placeholder="Enter the domain name"
              />
            </div>
            <div className="admin-form-row">
              <label>Min Price:</label>
              <input
                type="text"
                name="minPrice"
                value={formData.minPrice}
                onChange={handleChange}
                required
              />
            </div>
            <div className="admin-form-row">
              <label>Max Price:</label>
              <input
                type="text"
                name="maxPrice"
                value={formData.maxPrice}
                onChange={handleChange}
                required
              />
            </div>
            <div className="admin-form-row">
              <label htmlFor="zipFile">Zip File:</label>
              <input
                type="file"
                id="zipFile"
                name="zipFile"
                onChange={handleChange}
                accept=".zip"
                required
              />
            </div>
            {formData.zipFile && (
              <div className="admin-file-preview">
                <span>{formData.zipFile.name}</span>
                <button type="button" onClick={handleZipFileRemove}>
                  Remove
                </button>
              </div>
            )}
            <div className="admin-form-row">
              <label htmlFor="images">Images:</label>
              <input
                type="file"
                id="images"
                name="images"
                onChange={handleChange}
                accept="image/*"
                multiple
                required
              />
            </div>
            {formData.images.length > 0 && (
              <div className="admin-image-previews">
                {formData.images.map((image, index) => (
                  <div key={index} className="admin-image-preview">
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Preview ${index}`}
                      style={{ width: '100px', height: '100px' }}
                    />
                    <button type="button" onClick={() => handleImageRemove(index)}>
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div className="admin-form-row">
              <label>Project Details:</label>
              <textarea
                name="projectDetail"
                value={formData.projectDetail}
                onChange={handleChange}
                placeholder="Project Details"
                required
              />
            </div>
            <div className="admin-form-row">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;



