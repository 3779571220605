import React from 'react'
import './manage.css'
import {Link} from 'react-router-dom'
function Manage() {
  return (
    <div className='manageall'>
      <div className='manage-content'>
        <div className='manage-list'>
        <ul>
         <Link className='linkto' to='/manage/school'> <li>school</li> </Link>
           <Link className='linkto' to='/manage/college'> <li>college</li> </Link>
           <Link className='linkto' to='/manage/office'><li> office</li></Link>
           <Link className='linkto' to='/manage/hospital'><li>hospital</li> </Link>
           {/* <Link className='linkto' to='/manage/warehouse'><li>ware house</li></Link>
           <Link className='linkto' to='/manage/holesale'><li>hole sale</li> </Link>
           <Link className='linkto' to='/manage/shopmanagement'><li>shop management</li></Link>
           <Link className='linkto'to='/manage/restaurent'><li>restaurent</li></Link>
           <Link className='linkto' to='/manage/hotel'><li>hotel</li></Link> */}
        </ul>

          
        </div>
       

      </div>
       
    </div>
  )
}

export default Manage