

const ProjectForm = () => {
  return(  
  <div>
  We are currently working hard to bring you the best experience for managing projects. Stay tuned for updates on our latest features and improvements.
    </div>
    );
  };
  
  // const [formData, setFormData] = useState({
  //   title: '',
  //   sellerName: '',
  //   zipFile: null,
  //   images: [],
  //   projectDetail: ''
  // });

  // const handleChange = (e) => {
  //   const { name, value, files } = e.target;
  //   if (name === 'images') {
  //     setFormData(prevState => ({
  //       ...prevState,
  //       images: Array.from(files) // handle multiple image files
  //     }));
  //   } else if (name === 'zipFile') {
  //     setFormData(prevState => ({
  //       ...prevState,
  //       zipFile: files[0] // handle single file
  //     }));
  //   } else {
  //     setFormData(prevState => ({
  //       ...prevState,
  //       [name]: value // handle text input
  //     }));
  //   }
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('Form Data:', formData);

  //   // Reset the form data
  //   setFormData({
  //     title: '',
  //     sellerName: '',
  //     zipFile: null,
  //     images: [],
  //     projectDetail: ''
  //   });
  // };

  // return (
  //   <div className="container">
  //     <div className="form-wrapper">
  //       <form onSubmit={handleSubmit}>
  //         <div className="form-row">
  //           <label>Title:</label>
  //           <input
  //             type="text"
  //             name="title"
  //             value={formData.title}
  //             onChange={handleChange}
  //             placeholder="Title"
  //             required
  //           />
  //         </div>
  //         <div className="form-row">
  //           <label>Seller Name:</label>
  //           <input
  //             type="text"
  //             name="sellerName"
  //             value={formData.sellerName}
  //             onChange={handleChange}
  //             placeholder="Seller Name"
  //             required
  //           />
  //         </div>
  //         <div className="form-row">
  //           <label htmlFor='zipfile'>Zip File:</label>
  //           <input
  //             type="file"
  //             name="zipFile"
  //             onChange={handleChange}
  //             accept=".zip"
  //             required
  //             id='zipfile'
  //           />
  //         </div>
  //         <div className="form-row">
  //           <label htmlFor='images'>Images:</label>
  //           <input
  //             type="file"
  //             name="images"
  //             onChange={handleChange}
  //             accept="image/*"
  //             multiple
  //             id='images'
  //             required
  //           />
  //         </div>
  //         <div className="form-row">
  //           <label>Project Details:</label>
  //           <textarea
  //             name="projectDetail"
  //             value={formData.projectDetail}
  //             onChange={handleChange}
  //             placeholder="Project Details"
  //             required
  //           />
  //         </div>
  //         <div className="form-row">
  //           <button type="submit">Submit</button>
  //         </div>
  //       </form>
  //     </div>
  //   </div>


export default ProjectForm;

