import React from 'react'
import {Link} from 'react-router-dom'
import {motion,AnimatePresence} from 'framer-motion';
import './backdrop.css'

export default function Backdrop({ showModel, setShowModel }) {
    const backdropVariants = {
      visible: { opacity: 1 },
      hidden: { opacity: 0 },
      exit:{opacity: 0},
    };
    const Model ={
        hidden:{
            y:'-100vh',
            opacity:0
        },

        visible:{

            y:'250px',
            opacity:1,
            transition:{
                delay:0.3
            }
        },
    };
    return (
      <AnimatePresence  mode="wait">
        {showModel && (
          <motion.div className='backdrop' 
          variants={backdropVariants}
          initial='hidden'
          animate='visible'
          exit='exit'>

            <motion.div className="show-drop"

             variants={Model}
            
            >
                  
               <p>Thank you for visiting us! <br />We contact you soon!!</p>
                <Link to='/'>
                     
                    <button onClick={()=>setShowModel(false)}>EXIT</button>
                </Link>
            </motion.div>





          </motion.div>
            
          
          

         
        )
        
        }


      </AnimatePresence>
    );
  }
  
