import React from 'react';
import './TermsAndConditions.css'; // Import the external CSS file
import afotlogo3 from '../image/afot logo2.png'

function TermsAndConditions() {
    return (

        <div className="page8">    
          <img className='logo-term' src={afotlogo3} alt="" />          
            <center><h1>all for one tech</h1></center> <br />   
            
            <div className="upper">
           
            <p>At ALL FOR ONE TECH (AFOT), we strive to ensure that our customers are satisfied with their purchases. Below is our policy regarding refunds and cancellations</p> 
            <br />
            </div>
          
            <div className="policy-container">
            <img src={afotlogo3} alt="" /> 
      
      <ol className="policy-list">
        <li>
          Company Overview:
          <ul>
            <li>ALL FOR ONE TECH (AFOT) is dedicated to delivering cutting-edge software tools and freelance project services that adhere to the highest standards of quality, ethics, and innovation. We operate under the laws of India, specifically the state of Tamil Nadu, and are committed to ensuring that our business practices reflect our core values of integrity, customer satisfaction, and continuous improvement.
            </li>
          </ul>
        </li>
        <li>
           Mission Statement
          
          <ul>
            <li>Our mission is to empower individuals and businesses by providing advanced technology solutions and exceptional freelance services. We strive to be a trusted partner in the digital landscape, delivering products and services that enhance productivity, creativity, and growth.
            </li>
          </ul>
        </li>
        <li>
          Code of Conduct
          <ul>
            <li>At ALL FOR ONE TECH (AFOT), we believe in maintaining a professional and ethical environment for our employees, customers, and partners. Our code of conduct includes:
            </li>
            <li>Integrity: All employees and partners are expected to act with honesty and integrity in all business dealings.
            </li>
            <li>Respect: We promote a workplace that respects diversity, inclusivity, and individual rights.
            </li>
            <li>Confidentiality: Employees must protect the confidentiality of company and client information.
            </li>
            <li>Compliance: All employees are required to comply with applicable laws, regulations, and company policies.
            </li>
          </ul>
        </li>
        <img src={afotlogo3} alt="" /> 
        <li>Privacy Policy</li>
        <ul>
          <li>
          Your privacy is important to us. This section outlines how we collect, use, and protect your personal information.</li>
           <li>Information Collection: We collect information that you provide to us voluntarily when you register, make a purchase, or communicate with us. This may include your name, email address, billing information, and any other details necessary for the completion of transactions or services.
Use of Information: The information collected is used to process orders, provide customer support, send updates, and improve our services. We do not share your personal information with third parties except as necessary to fulfill your request or comply with legal obligations.
</li>

<li>Data Security: We implement robust security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
Cookies: Our website uses cookies to enhance user experience. Cookies are small files that are stored on your device when you visit our website. You can choose to disable cookies through your browser settings, but this may affect the functionality of our website.
</li>

        </ul>
        <li>
        Data Security and Protection

        <ul>
          <li>
          ALL FOR ONE TECH (AFOT) is committed to protecting the data entrusted to us by our customers and partners.  <br /> 
       <li >Security Measures: We use encryption, firewalls, and other security technologies to safeguard your data. Regular audits and updates are conducted to ensure the highest level of security.
       Data Retention: We retain personal data only for as long as necessary to fulfill the purposes for which it was collected or as required by law.</li>
 <li>
  
Data Breach Response: In the event of a data breach, we will notify affected individuals as required by law and take immediate steps to mitigate the impact and prevent future breaches.
 </li>
 <img src={afotlogo3} alt="" /> 

          </li>
          
        </ul>
        
        <li>
        Customer Service and Support <br />
           
        <ul>
          <li>  We are committed to providing exceptional customer service and support. <br />Our policies include:</li>
          <li>Support Availability: Our customer support team is available Monday to Friday, 9 AM to 6 PM IST, excluding public holidays.
          </li>
          <li>Response Time: We strive to respond to all inquiries within 24 hours on business days.
          </li>
          <li>Complaint Resolution: If you have a complaint, please contact our support team. We will investigate and resolve your issue as quickly as possible.
          </li>
        </ul>
              
              <li>Product and Service Delivery
              </li>
              <ul>
                <li>Product Delivery: Digital products are delivered via email or download link immediately upon payment confirmation. In rare cases, there may be delays due to technical issues, in which case we will notify you promptly.
                </li>
                <li>Service Delivery: Freelance projects are delivered automatically via download upon payment. If you encounter any issues with the download, please contact us immediately, and we will resend the project. However, no refunds will be provided for freelance services.
                </li>
                <li>Quality Assurance: We are committed to delivering products and services that meet or exceed client expectations. All products undergo rigorous testing, and services are reviewed for quality before delivery.
                </li>
                <img src={afotlogo3} alt="" /> 
              </ul>
              
              <li>Refunds and Cancellations
              </li>
              <ul>
                <li>Freelance Projects: There are no refunds for freelance projects. Once the project is purchased, it is delivered automatically. If you encounter any issues with the download or the project is missed, please contact us, and we will resend the project.
                </li>
                <li>Digital Products: Please refer to our Refunds/Cancellations Policy for detailed information on how refunds and cancellations for digital products are handled.
                </li>
              </ul>

              <li>
              Ethical Standards and Corporate Social Responsibility (CSR)
                    <ul>
                      <li>ALL FOR ONE TECH (AFOT) is dedicated to ethical business practices and corporate social responsibility.</li>
                      <li>Ethical Standards: We maintain high ethical standards in all aspects of our business, from product development to customer relations.
                      </li>
                      <li>CSR Initiatives: We are committed to giving back to the community through various CSR initiatives, including educational programs, environmental sustainability efforts, and charitable contributions.
                      </li>
                    </ul>
              </li>
        </li>
        
           <li> Intellectual Property
            <ul>
              <li>All content, trademarks, and intellectual property on our website are owned by ALL FOR ONE TECH (AFOT) unless otherwise stated.
              </li>
              <li>Usage Rights: We do not claim any copyrights over user-uploaded content. However, we verify all content uploaded by sellers to ensure it is original and not publicly available on platforms like GitHub. Any content found to be already in use or publicly available will be rejected.
              </li>
              <li>Third-Party Content: There is no third-party content on our website. All content is either created by our team or submitted by verified sellers.
              </li>
              <img src={afotlogo3} alt="" /> 
            </ul>
           
           </li>
          
           <li> Seller Verification and Content Protection
            <ul>
              <li>When users upload content for sale through the seller tab, we verify the content to ensure its originality. If the content is found to be already available on platforms like GitHub or any other public domain, it will be rejected. Once approved, the content is securely stored and sold on our freelance page, ensuring that it is not leaked or misused.
              </li>
            </ul>
            
           </li>

        </li>
       
        <li>
        Terms and Conditions
          <ul>
            <li>These Terms and Conditions govern the use of our website and the purchase of products and services through ALL FOR ONE TECH (AFOT). By using our website, you agree to comply with and be bound by the following terms:
            </li>
           
            <li>Eligibility: By using this website, you represent that you are at least 18 years old and have the legal authority to enter into a binding agreement.
<li>Account Registration: To access certain features, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
<li>Prohibited Activities: You agree not to use the website for any illegal activities, including but not limited to fraud, infringement of intellectual property rights, or unauthorized access to our systems.</li>
<li>Pricing and Payment: All prices listed on our website are in INR and are subject to change without notice. Payment must be made at the time of purchase through our secure payment gateway.</li>
<li>License: By purchasing digital products or services, you are granted a non-exclusive, non-transferable license to use the product or service as specified in the product description. Redistribution, resale, or unauthorized use is strictly prohibited.</li>  <img src={afotlogo3} alt="" />
<li>Disclaimer: ALL FOR ONE TECH (AFOT) makes no warranties, express or implied, regarding the accuracy, reliability, or completeness of the content on our website or the performance of our products and services.</li>
<li>Limitation of Liability: To the maximum extent permitted by law, ALL FOR ONE TECH (AFOT) will not be liable for any direct, indirect, incidental, or consequential damages arising out of your use of our website or products.</li>
<li>Termination: We reserve the right to terminate or suspend your account and access to our website at our sole discretion, without notice, for conduct that violates these terms or is otherwise harmful to our interests.</li>
</li>
 
          </ul>
          

        </li>

        <li>Amendments to the Policy
          <ul>
            <li>We reserve the right to update or modify this Company Policy at any time. Any changes will be effective immediately upon posting on our website. We encourage you to review this policy periodically to stay informed of any updates.

</li>
          </ul>
          
        </li>

        <li>Governing Law
          <ul>
            <li>
            This policy is governed by the laws of India, specifically the state of Tamil Nadu. Any disputes arising under this policy will be resolved in accordance with these laws.
            </li>
            
            </ul>
        </li>

        <li>
        Contact Information
          <ul>
            <li>If you have any questions or concerns about this Company Policy or any other matter, please contact us at: <br /> <br />

Gmail: allforonetech.in@gmail.com</li>
          </ul>
        </li>
        
      </ol>
    </div>
 
           
        </div>
     
    );
}

export default TermsAndConditions;



