import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import LoginPopup from './login-popup';
import './buy.css';
import { TbMapPinSearch } from 'react-icons/tb';

const Buy = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showPopup, setShowPopup] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      if (!isLoggedIn) return;

      try {
        const response = await axios.get('http://localhost:5001/api/freelance/projects');
        setProjects(response.data.projects);
        setFilteredProjects(response.data.projects);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, [isLoggedIn]);

  const handleSearch = async () => {
    if (!isLoggedIn) {
      alert('You must be logged in to search for projects.');
      return;
    }

    try {
      const response = await axios.get(`http://localhost:5001/api/freelance/search?searchTerm=${searchTerm}`);
      setFilteredProjects(response.data.projects);
    } catch (error) {
      console.error('Error searching projects:', error);
    }
  };

  const getImageUrl = (imagePath) => {
    return `http://localhost:5001/${imagePath}`;
  };

  const handleLoginSuccess = (user) => {
    console.log('User logged in:', user);
    setIsLoggedIn(true);
    setShowPopup(false);
    setUserInfo({
      photoURL: user.photoURL || '',
      email: user.email || 'No email provided',
      name: user.name || 'No name provided'
    });
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserInfo(null);
    setShowDropdown(false);
  };

  const handleSwitchAccount = () => {
    setIsLoggedIn(false);
    setShowPopup(true);
    setUserInfo(null);
    setShowDropdown(false);
  };

  return (
    <div className='mainsearch'>
      <div className="search-container">
        {showPopup && !isLoggedIn && (
          <LoginPopup
            handleClose={() => setShowPopup(false)}
            handleFormSubmit={(user) => handleLoginSuccess(user)}
          />
        )}

        {!showPopup && isLoggedIn && (
          <>
            <div className='search-input'>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search anything"
              />
              <button onClick={handleSearch}>Search</button>
              {userInfo.photoURL ? (
                <div className='profil-pic' onClick={() => setShowDropdown(!showDropdown)}>
                  <img src={userInfo.photoURL} alt="Profile" className="profile-pic" />
                </div>
              ) : (
                <div className="profile-pic-placeholder" onClick={() => setShowDropdown(!showDropdown)}>
                  No profile picture
                </div>
              )}
              {showDropdown && (
                <div className="dropdown-menu">
                  <p>Email: {userInfo.email}</p>
                  <p>Name: {userInfo.name}</p>
                  <button onClick={handleLogout}>Logout</button>
                  <button onClick={handleSwitchAccount}>Switch Account</button>
                </div>
              )}
            </div>

            <div className="search-result">
              {filteredProjects.map(project => (
                <div key={project.id} className="project-item">
                  <div className="slider-container">
                    <div className="slide-img">
                      {project.images && project.images.length > 0 && project.images.map((imagePath, index) => (
                        <img
                          key={index}
                          src={getImageUrl(imagePath)}
                          width="50"
                          height="60"
                          alt={project.title}
                          className="slider-image"
                          onError={(e) => e.target.style.display = 'none'}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="content-search">
                    <h3>Title: {project.title}</h3>
                    <p>Domain: {project.domainname}</p>
                    <h1>Project Detail:</h1>
                    <p className='para44'>{project.projectdetail}</p>
                    <Link className='link-search' to={`/freelance/${project.title}`}>View Details</Link>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Buy;


          






