import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function PanelDisplay() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://afot.in:5000/api/login', {
        username,
        password
      });

      if (response.status === 200 && response.data.isAuthenticated) {
        setIsAuthenticated(true);
      } else {
        alert('Invalid username or password');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('Login failed. Please try again.');
    }
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
      backgroundColor:'whitesmoke'

    }}>
      {isAuthenticated ? (
        <div>
          <Link style={{
            textDecoration: 'none',
            fontSize: '28px',
            color: 'black',
            textTransform: 'capitalize',
            padding: '20px',
            fontWeight: 'bolder'
          }} to='/admin/free'>freelanceadminpanel</Link>

          <Link style={{
            textDecoration: 'none',
            fontSize: '28px',
            color: 'black',
            textTransform: 'capitalize',
            fontWeight: 'bolder'
          }} to='/admin/prod'>productadminpanel</Link>
        </div>
      ) : (
        <form onSubmit={handleLogin} style={{ textAlign: 'center',backgroundColor:'whitesmoke' }}>
          <div >
            <label>
              Username:
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{ margin: '10px', padding: '10px', fontSize: '16px' }}
              />
            </label>
          </div>
          <div>
            <label>
              Password:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ margin: '10px', padding: '10px', fontSize: '16px' }}
              />
            </label>
          </div>
          <button type="submit" style={{
            margin: '10px',
            padding: '10px 20px',
            fontSize: '16px',
            fontWeight: 'bolder',
            backgroundColor: 'black',
            color: 'white',
            border: 'none',
            cursor: 'pointer'
          }}>Login</button>
        </form>
      )}
    </div>
  );
}

export default PanelDisplay;

