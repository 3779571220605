import React, { useEffect, useState } from 'react';
import './contect.css';
import hour from '../image/hour 24.png';
import communication from '../image/communication.png';
import message from '../image/message.png';
import location from '../image/location.png';

export default function Contact({ setShowModel }) {
  const [address, setAddress] = useState("EDC CELL panimalar Engineering college poonamallae nazaraethpet chennai");
  const [phoneNumbers, setPhoneNumbers] = useState(['9025486458', '9345670169']);
  const [emails, setEmails] = useState([ 'allforonetech.in@gmail.com']);
  const [openHours, setOpenHours] = useState(['Monday-friday 9:00AM-5:00pm']);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (formSubmitted) {
      // Execute after form submission
      setTimeout(() => {
        setShowModel(true);
      }, 500); // Change the timeout to 500 milliseconds (0.5 seconds)
    } 
  }, [formSubmitted, setShowModel]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission

    try {
      const response = await fetch('https://afot.in:5000/submit-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        // Clear form after successful submission
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
        setFormSubmitted(true);
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className='main-div' >

  
    <div className=' container body-content'>
        <div className=" row content-mainbox">
          <div className=" col-lg-6 col-md-6 content-firstbox">
            <div className=" row content-firstflex">
              <div className=" col-lg-5 col-5 col-sm-5 col-md-5 content-address">
                <img src={location} alt="" />
                <h3>Address</h3>
                <p>{address}</p>
              </div>
              <div className=" col-lg-5 col-5   col-md-5 content-callus">
                <img src={communication} alt="" />
                <h3>Call Us</h3>
                {phoneNumbers.map((phoneNumber, index) => (
                  <p key={index}>{phoneNumber}</p>
                ))}
              </div>
            </div>
            <div className=" row content-secondflex">
              <div className=" col-lg-5 col-5 col-sm-5 col-md-5  content-emailus">
                <img src={message} alt="" />
                <h3>Email Us</h3>
                <address>
  {emails.map((email, index) => (
    <a href={`mailto:${email}`} key={index}>{email}</a>
  ))}
</address>


                
              </div>
              <div className=" col-lg-5 col-5 col-sm-5 col-md-5   content-openhours">
                <img src={hour} alt="" />
                <h3>Open Hours</h3>
                {openHours.map((hour, index) => (
                  <p key={index}>{hour}</p>
                ))}
              </div>
            </div>
          </div>
          
          <div className=" col-lg-6 col-sm-11 col-md-6  content-form ">
            
        

          <form onSubmit={handleSubmit}>
            <div className="row form-content">

              <div className="col-lg-6 col-md-4 col-6 first">

              <label className="content-yourname" htmlFor="name">Name</label>

              <input className="content-yoursemail" id="name" name="name" value={formData.name} onChange={handleChange} required /> <br /> 
  
              </div>
              <div className="col-lg-6 col-md-4 col-6 first">
              <label className="content-youremail" htmlFor="email"> Email </label>
              <input className="content-yoursemail" id="email" name="email" value={formData.email} onChange={handleChange} required /><br />
              </div>

              <div className="col-lg-12 col-md-1 col-12 second">
                      
              <label htmlFor="subject">Subject </label>
              <input className="subject" name="subject" value={formData.subject} onChange={handleChange} required /><br />
              </div>
<div className="col-lg-6 col-sm-12 third">
<label htmlFor="message">Message</label>
              <textarea className="content-message" name="message" value={formData.message} onChange={handleChange} required /><br />
</div>

<div  className='button-div '><button className="content-button" type="submit">Submit</button> </div>
                 
            </div>
              
            
           
             
            </form>
          </div>
           
            </div>
          
          </div>
      
    
     
    </div>
  );
}
