import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Navbar from "./Navbar.js";
import Error from './error.js';
import Products from "./products.js";
import Contact from './Contact.js';
import About from './about/About.js';
import Company from './Company.js';
import Buy from './Buy.js';
import Sell from './Selling.js';
import Model from './backdrop.js';
import Final from './pages/projects.js';
import Modify from './pages/Modification.js';
import Paper from './pages/paper work.js';
import Hack from './pages/hackathon.js';
import Hard from './pages/hardware bases.js';
import Manage from './pages/Manage.js';
import Comp from './pages/Comp.js';
import CardOne from './about/cardone.js';
import Hardmodify from './pages/Hardwaremodify.js';
import Softmodify from './pages/Softwaremodify.js';


import School from '../navigation/pages/manage/School.js';
import College from '../navigation/pages/manage/College.js';
import Hospitel from '../navigation/pages/manage/Hospitel.js';
// import Hole from '../navigation/pages/manage/Holesale.js';
// import Hotel from '../navigation/pages/manage/Hotel.js';
import Office from '../navigation/pages/manage/Office.js';
// import Rest from '../navigation/pages/manage/Restaurent.js';
// import Shop from '../navigation/pages/manage/Shopmanagement.js';
// import Ware from '../navigation/pages/manage/Warehouse.js';
import Admin from './adminPanels/paneldisplay.js';
import Prod from './adminPanels/productadmin.js'
import Free from './adminPanels/freelanceadmin.js'
import FreelanceViewPage from './pages/freelance_viewpage.js';
import Terms from './Termsand.js'
import './home.css';

function Home() {
  const [showModel, setShowModel] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const isFormSubmitted = JSON.parse(localStorage.getItem('isFormSubmitted')) || false;
    setIsFormSubmitted(isFormSubmitted);

    // Show popup after 2 seconds if the form has not been submitted
    if (!isFormSubmitted) {
      const popupTimeout = setTimeout(() => {
        setShowPopup(true);
      }, 2000); // 2 seconds in milliseconds

      return () => clearTimeout(popupTimeout);
    }
  }, []);

  useEffect(() => {
    let intervalId;
    if (!showPopup && !isFormSubmitted) {
      // Show popup every 1 minute after it is closed and if form is not submitted
      intervalId = setInterval(() => {
        setShowPopup(true);
      }, 60000); // 1 minute in milliseconds
    }

    return () => clearInterval(intervalId);
  }, [showPopup, isFormSubmitted]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleFormSubmit = () => {
    setIsFormSubmitted(true);
    localStorage.setItem('isFormSubmitted', JSON.stringify(true));
    setShowPopup(false);
  };

  const [projects, setProjects] = useState([]);

  const addProject = (newProject) => {
    setProjects([...projects, newProject]);
  };



  const [projectsList, setProjectsList] = useState([]);
    
  
  const addNewProject = (project) => {
    const updatedProjects = [...projectsList, project];
    setProjectsList(updatedProjects);
    localStorage.setItem('projects', JSON.stringify(updatedProjects));
  };

  useEffect(() => {
    // Load projects from local storage on component mount
    const storedProjects = JSON.parse(localStorage.getItem('projects')) || [];
    setProjectsList(storedProjects);
  }, []);

  const deleteProject = (index) => {
    const updatedProjects = projectsList.filter((_, i) => i !== index);
    setProjectsList(updatedProjects);
    localStorage.setItem('projects', JSON.stringify(updatedProjects));
  };
  return (
    <div className='back-ground' >
      <div className={`home-div ${showPopup ? 'blur-background' : ''}`}>
        <Router>
          <Navbar />
          <Model showModel={showModel} setShowModel={setShowModel} />
          
          <Routes>
            <Route path="/admin" element={<Admin />} />
        
            <Route path='/admin/free' element={<Free  addNewProject={addNewProject} projectsList={projectsList} deleteProject={deleteProject} />} />
            <Route path='/admin/prod' element={<Prod onAddProject={addProject} />} />
            <Route path="*" element={<Error />} />
            <Route path="/products" element={<Products projects={projects} />} />
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/contact" element={<Contact setShowModel={setShowModel} />} />
            <Route path="/about" element={<About setShowModel={setShowModel} />} />
            <Route path="/about/cardone" element={<CardOne />} />
            <Route path="/" element={<Company />} />
            <Route path="/buy" element={<Buy  projectList={projectsList} />} />
            <Route path="/sell" element={<Sell setShowModel={setShowModel} />} />
            <Route path='/project' element={<Final setShowModel={setShowModel} />} />
            <Route path='/paper work' element={<Paper setShowModel={setShowModel} />} />
            <Route path='/Hackathon' element={<Hack setShowModel={setShowModel} />} />
            <Route path='/Hardware Bases' element={<Hard setShowModel={setShowModel} />} />
            <Route path='/modification' element={<Modify setShowModel={setShowModel} />} />
            <Route path='/modify/hardmodify' element={<Hardmodify setShowModel={setShowModel} />} />
            <Route path='/modify/softmodify' element={<Softmodify setShowModel={setShowModel} />} />
            <Route path='/Managements' element={<Manage />} />
            <Route path='/manage/school' element={<School setShowModel={setShowModel} />} />
            <Route path='/manage/college' element={<College setShowModel={setShowModel} />} />
            <Route path='/manage/hospital' element={<Hospitel setShowModel={setShowModel} />} />
            {/* <Route path='/manage/holesale' element={<Hole />} /> */}
            {/* <Route path='/manage/hotel' element={<Hotel />} /> */}
            <Route path='/manage/office' element={<Office setShowModel={setShowModel} />} />
            {/* <Route path='/manage/restaurent' element={<Rest />} />
            <Route path='/manage/shopmanagement' element={<Shop />} />
            <Route path='/manage/warehouse' element={<Ware />} /> */}
            <Route path='/Company Projects' element={<Comp setShowModel={setShowModel} />} />
            <Route path='term' element={<Terms />} />
            <Route path='/freelance/:title' element={<FreelanceViewPage />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default Home;


