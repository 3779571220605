import React from 'react'
import '../App.css'
function Error() {
  return (
    <div className='error-message' style={{display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height:'80vh',
        fontSize:'30px',
        fontWeight:'bolder',
        
        
    }}>404-error!....... </div>
  )
}

export default Error