
import image1 from '../image/website.png' 
import image from '../image/logo.png' 
import './company.css'
import { Link } from 'react-router-dom';
// import { CountContext } from "../App.js";
// import { useContext } from "react";
import logo from '../image/afot logo2.png'
import react from '../image/logo/react-removebg-preview.png'
import python from '../image/logo/python-removebg-preview.png'
import php from '../image/logo/php-removebg-preview.png'
import mysql from '../image/logo/mysql-removebg-preview.png'
import iot from '../image/logo/iot-removebg-preview.png'
import node from '../image/logo/node-removebg-preview.png'
import linux from '../image/logo/linux-removebg-preview.png'
import java from '../image/logo/js.png'
import machine from '../image/logo/machine-removebg-preview.png'
import css from '../image/logo/css.png';
import html from '../image/logo/html-removebg-preview.png'
import flutter from '../image/logo/flutter.png'
import youtube from '../image/logos/New folder/youtube.png'
import instagram from '../image/logos/New folder/instagram.png'
import facebook from '../image/logos/New folder/facebook.png'
import linkedin from '../image/logos/New folder/linkedin.png'
import twitter from '../image/logos/New folder/twitter.png'

import { useState } from 'react';

export default function Company() {
  
  
  const [expand, setExpand] = useState(false);
  const items = ["Project", 'Paper Work', 'Hackathon','Modification',  'Managements', 'Company Projects'];
   
  //  const { darkTheme } = useContext(CountContext);
  return(

     
    <>
 
  

   <div className="page1">

   <div className='row page-height'>


      
<div  className=" title  col-lg-7  col-md-6 col-12 col-sm-7" >
<div data-aos="fade-right"

data-aos-duration="900"
data-aos-easing="ease-in-sine">
<h1 className='afot'>ALL FOR ONE TECH</h1>
<p className='p1'>We offer Software and Hardware services for  your projects, provide freelance support, and assist with project development and hackathon endeavors,Let us be your trusted ally in achieving your goals</p></div>
</div>
<div className=" pic col col-lg-5  col-12   col-md-6 col-sm-5 " >
<div data-aos="fade-left"

data-aos-duration="900"
data-aos-easing="ease-in-sine">
<img className='roo img-fluid me-auto' src={image} alt="" />
</div>
  
</div>



</div>

<div className="scroll-down ">
  

<span ></span>
<span></span>
<span></span>
<span></span>
<span></span>
<span></span>

  </div>


</div>



   
  


 
        

      
      
    
      

        


    <div className='page2'>

    
    {/* <div data-aos="flip-right" data-aos-easing="linear"
     data-aos-duration="800" className='seee1'>
    <button onClick={() => setExpand(!expand)}>{expand ?'Seeless':'Seemore'}</button>
    </div> */}
   
    
    
   
      
    <div data-aos="fade-right"
    data-aos-easing="linear"
    data-aos-duration="500">
    <div className='head5'>
          <h6>OUR SERVICES</h6>
        
        </div>
    </div>
      
        

        <div className='ver'>
     
        <div className="hei">
      <div data-aos="fade-up"
     
     
     >

  
      <ul className='vertical'>

{items.map((item, index) => (
  
<Link  key={index} className='link'to={`/${item.toLowerCase()}`}><li>{item}</li></Link>
  
))}
</ul>
      </div>
      
</div>
      
       
     
        </div>
        

        
       </div>  
      
        
      
        
        

       <div className='page4'>

       <div data-aos="fade-up-right"
       data-aos-easing="linear">
       <div className='working'> THE TECHNOLOGY WE ARE CURRENTLY UTILIZING</div>
       </div>

        

        
 

       <div data-aos="fade-up"
       
       data-aos-easing="linear"
     data-aos-duration="500">
       <div 
         
         className=" working-technology container">
       
           
     <div className="row row-work">
   
     <div  className='box col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' > <img src={react} alt="" /><h1>react js</h1></div>
    <div className='box col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'><img src={python} alt="" /><h1>python</h1></div>
    <div className='box col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' ><img src={php} alt="" /><h1>pHP</h1></div>
    <div className='box col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'><img src={mysql} alt="" /><h1>mysql</h1></div>
   
     </div>
   
     <header className='row row-work'>
     <div className='col-1 col-sm-1 col-md-1 box col-lg-1 col-xl-1 ' ><img src={iot} alt="" /><h1>IOT</h1></div>
     <div className=' col-1 col-sm-1 col-md-1 box col-lg-1 col-xl-1'><img src={node} alt="" /><h1>node js</h1></div>
     <div className='col-1 col-sm-1 col-md-1 box col-lg-1 col-xl-1'><img src={linux} alt="" /><h1>linux</h1></div>
     <div className=' col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 box'><img className='javapic' src={java} alt="" /><h1 className='java'>javascript</h1></div>
   
     </header>
   
   <head className='row row-work'>
   <div className=' col-1 col-sm-1 col-md-1col-lg-1 col-xl-1 box '><img className='machine-pic' src={machine} alt="" /><h1 className='machine'>machine <br />learning</h1></div>
   <div className=' col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 box'><img src={html} alt="" /><h1>hTML</h1></div>
   <div className=' col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 box'><img className='flutterpic' src={flutter} alt="" /><h1 className='flutter'>flutter</h1></div>
   <div className=' col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 box'><img  src={css} alt="css" /><h1 className='flutter'>cSS</h1></div> 
   </head>
   
   
   </div>
       </div>


                                 

      </div>

                           

   
      <div className="page5">

<div data-aos="zoom-in-up"
data-aos-easing="linear"
data-aos-duration="900">
<div className="firs row">

<div className="col-lg-1 col-sm-10 col-md-1   img-last ">
<img src={logo} alt="" />

</div>


</div>
 </div>        
      
 <div data-aos="fade-left"
  data-aos-duration="1500">
 <div className="col-lg-12 col-md-12 col-sm-12 p-last">
<p>
your final impact statement goes here. <br />
make it memorable!
</p>
</div>
 </div>



<div className="last row">


       
       <div  data-aos="zoom-in-down"
        data-aos-duration="1700" className="col-lg-4 col-6 col-sm-4 col-md-4  last-d">
        <h1>connect with us</h1>
        <h4>instagram</h4>
        <h4>twitter</h4>
        <h4>facebook</h4>
        <h4>linkedin</h4>
      
       </div>
       
       
       
       
       <div  data-aos="zoom-in-down"
        data-aos-duration="1900"  className="col-lg-3 col-6 col-sm-3 col-md-3 last-d">
        <h1>company</h1>
        <ul>
          <Link className='last-link' to='/about'><li>about us</li></Link>
          <Link className='last-link' to='contact'><li>contact us</li></Link>
          <Link className='last-link' to='term'> <li>Terms & Conditions </li></Link>
          <li>legal</li>
        </ul>
       </div>
       
      
     
        
       <div data-aos="zoom-in-down"
        data-aos-duration="2100" className="col-lg-4 col-sm-4 col-12 col-md-4  last-d">
        <h1>contact</h1>
        <h5>allforonetech.in@gmail.com</h5>
        <h4>9025486458</h4>
       </div>
       
       
       

</div>
<div data-aos="fade-up"
  data-aos-anchor-placement="top-bottom"
  data-aos-duration="1500" className=" last-img">
<div className='row'>
<div  className="col-lg-1 col-sm-1 col-md-1 col-2">
<a href="https://youtube.com/@allforonetech?si=Zh8S2xb_Yd8SSJeU"><img className='icons' src={youtube} alt="" /></a>
</div>


<div 
   className="col-lg-1 col-sm-1 col-md-1 col-2">

<a href=""><img className='icons' src={instagram} alt="" /></a>

</div>
<div className="col-lg-1 col-sm-1 col-md-1 col-2 ">
<a href="https://www.linkedin.com/company/all-for-one-tech/"><img className='icons'  src={linkedin} alt="" /></a>
</div>
<div className="col-lg-1 col-sm-1 col-md-1 col-2">
<a href=""><img className='icons'   src={facebook} alt="" /></a>
</div>
<div className="col-lg-1 col-sm-1 col-md-1 col-2 twitter ">
<a href=""><img className='icons' src={twitter} alt="" /></a>
</div>
</div>
</div>



    

 </div>
    
    
     



     


        
      
   
       
                     
       
         
             
         
      
          
                      
                              
                                 
      
    
      
    
  </>  
  )
  }
 



  


