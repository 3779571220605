import React from 'react'
import { Link } from 'react-router-dom';
import './modify.css'

function Gove() {
  return (
    <div className='modify-all'>

      <div className='container modify-cont'>
      
      <div className='body-button'>
      <div className="row modify">
          
      <div className="link-border">
          <div className="col-lg-3 col-6 col-md-2 background-button">
            
            <Link className='link-button' to='/modify/hardmodify'> hardwaremodification</Link>
            
            
          </div>
          <div className="col-lg-2 col-md-2 col-4 ">
            <Link className='link-button' to='/modify/softmodify'
            > softwaremodification</Link>
          </div>

        </div>
        

          

         
        </div>
      </div>
       

      </div>


    </div>
  )
}

export default Gove