
import Home from './navigation/Home.js';
import Preloader from './navigation/Preloader.js';

import './App.css';


import React, { createContext, useState, useEffect } from 'react';

// Create the CountContext with default values
export const CountContext = createContext();

function App() {
  const [load, setLoad] = useState(true);
  const [darkTheme, setDarkTheme] = useState(false);

  useEffect(() => {
    // Simulate a loading time for 2 seconds
    setTimeout(() => {
      setLoad(false);
    }, 2000);
  }, []);

  useEffect(() => {
    // Check the default browser theme
    const mediaQuery = window.matchMedia('(prefers-color-scheme:light)');
    setDarkTheme(mediaQuery.matches);

    // Listen for changes in the preferred color scheme
    const handleChange = (e) => {
      setDarkTheme(e.matches);
    };
    mediaQuery.addEventListener('change', handleChange);

    // Cleanup listener on component unmount
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  const toggleTheme = () => {
    setDarkTheme((prevTheme) => !prevTheme);
  };

  return (
    <CountContext.Provider value={{ darkTheme, toggleTheme }}>
      <div className={darkTheme ? 'dark-theme' : 'light-theme'}>
        
        {load ? <Preloader /> : <Home />}
        
      </div>
    </CountContext.Provider>
  );
}

export default App;
