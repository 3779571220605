import './hack.css';
import React, { useEffect, useState } from 'react';

function Hack({ setShowModel }) {
  const COOLDOWN_PERIOD = 30 * 60 * 60 * 1000; // Cooldown period in milliseconds (30 hours)
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const storedTimestamp = localStorage.getItem('hackFormSubmittedTimestamp');
    if (storedTimestamp) {
      const lastSubmittedTime = parseInt(storedTimestamp, 10);
      const now = Date.now();

      if (now - lastSubmittedTime < COOLDOWN_PERIOD) {
        setFormSubmitted(true);
        setTimeRemaining(COOLDOWN_PERIOD - (now - lastSubmittedTime));
      } else {
        localStorage.removeItem('hackFormSubmittedTimestamp');
      }
    }
  }, []);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          const newTime = prevTime - 1000;
          if (newTime <= 0) {
            clearInterval(timer);
            localStorage.removeItem('hackFormSubmittedTimestamp');
            setFormSubmitted(false);
            return 0;
          }
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeRemaining]);

  const [formData, setFormData] = useState({
    teamName: '',
    leaderName: '',
    projectTitle: '',
    hackathondate: '',
    hackathon: '',
    mobileNumber: '',
    gmail: '',
    college: '',
    groupOrSolo: '',
    solutionStatement: '',
    whatToDo: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formSubmitted) return;

    try {
      const response = await fetch('https://afot.in:5000/submit-form3', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error submitting form', error);
    }

    const timestamp = Date.now();
    localStorage.setItem('hackFormSubmittedTimestamp', timestamp.toString());
    setFormSubmitted(true);
    setTimeRemaining(COOLDOWN_PERIOD);

    // Show the modal after a short delay
    const timeout = setTimeout(() => {
      setShowModel(true);
    }, 500); // 500 milliseconds delay

    return () => clearTimeout(timeout);
  };

  return (
    <div className="allpa">
      <div className="allhack">
        <div className="head-hack text-nowrap">PROJECT PROPOSAL FORM</div>
        <div className="container cont-hack">
          <div className="body-hack">
            {formSubmitted ? (
              <p>You have already submitted the form. Please try again later.</p>
            ) : (
              <form className="form" onSubmit={handleSubmit}>
                <div className="row hack-project">
                  <div className="form-group col-1 col-sm-1 col-md-2 col-lg-2 hack">
                    <input
                      className="inputha1"
                      type="text"
                      id="teamName"
                      name="teamName"
                      value={formData.teamName}
                      onChange={handleChange}
                      required
                    />
                    <label className="labelha1" htmlFor="teamName">Team Name</label>
                  </div>
                  <div className="form-group col-1 col-sm-1 col-md-2 col-lg-2 hack">
                    <input
                      className="inputha2"
                      type="text"
                      id="leaderName"
                      name="leaderName"
                      value={formData.leaderName}
                      onChange={handleChange}
                      required
                    />
                    <label className="labelha2" htmlFor="leaderName">Leader Name</label>
                  </div>
                  <div className="form-group col-1 col-sm-1 col-md-2 col-lg-2 hack">
                    <input
                      className="inputha1"
                      type="tel"
                      id="mobileNumber"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      maxLength="10"
                      pattern="[0-9]{10}"
                      title="Please enter a valid mobile number (10 digits)"
                      required
                    />
                    <label className="labelha1 text-nowrap" htmlFor="mobileNumber">Mobile Number</label>
                  </div>
                  <div className="form-group col-1 col-sm-1 col-md-2 col-lg-2 hack">
                    <input
                      className="inputha2"
                      type="email"
                      id="gmail"
                      name="gmail"
                      value={formData.gmail}
                      onChange={handleChange}
                      required
                    />
                    <label className="labelha2" htmlFor="gmail">Gmail</label>
                  </div>
                  <div className="form-group col-1 col-sm-1 col-md-2 col-lg-2 hack">
                    <input
                      className="inputha1"
                      type="text"
                      id="college"
                      name="college"
                      value={formData.college}
                      onChange={handleChange}
                      required
                    />
                    <label className="labelha1" htmlFor="college">College/University/Institute</label>
                  </div>
                  <div className="form-group col-1 col-sm-1 col-md-2 col-lg-2 hack">
                    <input
                      className="inputha2"
                      type="text"
                      id="projectTitle"
                      name="projectTitle"
                      value={formData.projectTitle}
                      onChange={handleChange}
                      required
                    />
                    <label className="labelha2" htmlFor="projectTitle">Project Title</label>
                  </div>
                  <div className="form-group col-1 col-sm-1 col-md-2 col-lg-2 hack">
                    <input
                      className="inputha1"
                      type="date"
                      id="hackathondate"
                      name="hackathondate"
                      value={formData.hackathondate}
                      onChange={handleChange}
                      pattern="[0-9]{4}"
                      title="Please enter a valid year (e.g., 2024)"
                      required
                    />
                    <label className="labelha1" htmlFor="year">Hackathon last date</label>
                  </div>
                  <div className="form-group col-1 col-sm-1 col-md-2 col-lg-2 hack">
                    <input
                      className="inputha2"
                      type="text"
                      id="hackathon"
                      name="hackathon"
                      value={formData.hackathon}
                      onChange={handleChange}
                      required
                    />
                    <label className="labelha2" htmlFor="hackathon">Hackathon Name</label>
                  </div>
                  <div className="form-group col-lg-3 col-md-2 col-sm-2 col-1 hack">
                    <input
                      className="rbutton"
                      type="radio"
                      id="group"
                      name="groupOrSolo"
                      value="group"
                      onChange={handleChange}
                      required
                    />
                    <label className="radio-button" htmlFor="group">Group</label>
                  </div>
                  <div className="form-group col-1 col-lg-3 hack">
                    <input
                      className="gbutton"
                      type="radio"
                      id="solo"
                      name="groupOrSolo"
                      value="solo"
                      onChange={handleChange}
                      required
                    />
                    <label className="radio-sbutton" htmlFor="solo">Solo</label>
                  </div>
                  <div className="form-group textarea hack">
                    <textarea
                      className="textarea-project"
                      id="solutionStatement"
                      cols="30"
                      rows="10"
                      name="solutionStatement"
                      value={formData.solutionStatement}
                      onChange={handleChange}
                      required
                    />
                    <label className="labelftxt text-nowrap" htmlFor="solutionStatement">Solution Statement</label>
                  </div>
                  <div className="form-group textarea hack">
                    <textarea
                      className="textarea-project"
                      id="whatToDo"
                      cols="30"
                      rows="10"
                      name="whatToDo"
                      value={formData.whatToDo}
                      onChange={handleChange}
                      required
                    />
                    <label className="labelftxt text-nowrap" htmlFor="whatToDo">What Should We Do</label>
                  </div>
                  <div className="form-group button hack">
                    <button type="submit">SUBMIT</button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hack;



