import { useEffect, useCallback } from 'react';
import { auth, googleAuthProvider } from './firebase';
import { signInWithRedirect, getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import Cookies from 'js-cookie';

const LoginPopup = ({ handleClose, handleFormSubmit }) => {

  const handleSignIn = useCallback(async () => {
    try {
      const result = await getRedirectResult(auth);
      if (result && result.user) {
        const user = result.user;
        console.log("User found after redirect:", user); // Debugging log
        Cookies.set('authToken', user.accessToken, { expires: 7 });
        handleFormSubmit(user); // Submit user info
        handleClose(); // Close popup
      } else {
        console.log("No user found, redirecting to Google Sign-In");
        await signInWithRedirect(auth, googleAuthProvider);
      }
    } catch (error) {
      console.error("Error during Google Sign-In:", error.message);
    }
  }, [handleClose, handleFormSubmit]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User already signed in:", user);
        Cookies.set('authToken', user.accessToken, { expires: 7 });
        handleFormSubmit(user); // Submit user info
        handleClose(); // Close popup
      } else {
        console.log("No user signed in, invoking handleSignIn");
        handleSignIn(); // Initiate sign-in if no user is found
      }
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, [handleSignIn, handleClose, handleFormSubmit]);

  return null; // Render nothing
};

export default LoginPopup;
