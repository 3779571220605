import React, { useEffect, useState } from 'react';
import './comp.css';

function Comp({setShowModel}) {

  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (formSubmitted) {
      // Execute after form submission
      const timeout = setTimeout(() => {
        setShowModel(true);
      }, 100); // Change the timeout to 500 milliseconds (0.5 seconds)
  
      // Clear the timeout after 1 hour (3600000 milliseconds)
      const cooldownTimeout = setTimeout(() => {
        console.log('Cooldown timeout reached. Resetting formSubmitted to false.');
        setFormSubmitted(false);
      }, 3600000); // 1 hour in milliseconds
  
      return () => {
        clearTimeout(timeout);
        clearTimeout(cooldownTimeout);
      };
    }
  }, [formSubmitted, setShowModel]);




  const [formData, setFormData] = useState({
    companyName: '',
    mailId: '',
    number: '',
    team: '',
    projectDetail: '',
    what: '',
    selectValue: '',
    teamdetials: '',
    howManyMember: '',
    startDate: '',
    endDate: '',
    meetingArrangement: '',
    preference: '',
    whatTime: '',
    message:'',
    frontend:'',
    backend:'',
    fullstack:'',
    machinelearning:'',
    other:''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setFormData({ ...formData, team: selectedValue });
  };

  const handleSelectChange = (e) => {
    setFormData({ ...formData, selectValue: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://afot.in:5000/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error submitting form', error);
    }

    setFormSubmitted(true);
  };

  return (
    <div className="allcomp">
      <div className="head-comp text-nowrap">PROJECT PROPOSAL FORM</div>
      <div className="container cont-comp">
        <div className="body-comp">
          <form className="form" onSubmit={handleSubmit}>
            <div className="row comp-project">
              <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
                <input
                  className="inputcomp"
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                />
                <label className="labelcomp" htmlFor="companyName">Company Name</label>
              </div>
              <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
                <input
                  className="inputcomp"
                  type="email"
                  id="mailId"
                  name="mailId"
                  value={formData.mailId}
                  onChange={handleChange}
                  required
                />
                <label className="labelcomp" htmlFor="mailId">Mail ID</label>
              </div>
              <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
               
              <input className=' inputcomp' type="tel" id='num' name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} maxLength="10" pattern="[0-9]{10}" title="Please enter a valid mobile number (10 digits)" required />
                <label className=' labelcomp text-nowrap' htmlFor="num">Mobile Number</label>
              </div>
            
              <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
                <select
                  className="selectcomp"
                  name="selectValue"
                  value={formData.selectValue}
                  onChange={handleSelectChange}
                  required
                >
                  <option value="" disabled>Select an option</option>
                  <option value="project">Project</option>
                  <option value="team"> Hire Team</option>
                  <option value="meeting">Meeting</option>
                </select>

 
  
          
              </div>
     
             

              {formData.selectValue === 'project' && (
                <div>
                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
                  <textarea
                    className="textareacomp"
                    id="projectDetail"
                    name="projectDetail"
                    value={formData.projectDetail}
                    onChange={handleChange}
                    required
                  />
                  <label className="labeltxtcomp" htmlFor="projectDetail">Project Detail</label>
                </div>
                <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp" style={{ marginBottom: '100px' }}>
                  <textarea
                    className="textareacomp"
                    id="what"
                    name="what"
                    value={formData.what}
                    onChange={handleChange}
                    required
                  />
                  <label className="labeltxtcomp" htmlFor="what">What to do</label>
                </div>
                <div className="row">
                  {/* <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
                  <textarea
                    className="textareacomp"
                    id="message"
                    name="projectDetail"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <label className="labeltxtcomp" htmlFor="message">Message</label>
                </div> */}

                <div className="form-group button comp">
                    <button type='submit'>SUBMIT</button>
                </div>

              </div>  
                
                </div>
              )}

              {formData.selectValue === 'team' && (
                <div >
                  <div className='row'>

                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
                  <select
      className="selectcomp"
      name="teamdetials"
      value={formData.teamdetials}
      onChange={handleChange}
      required
    >
       <option value="" disabled>Team Peference </option>
<option value="machinelearning">Machine Learning</option>
<option value="frontend">Frontend</option>
<option value="backend">Backend</option>
<option value="fullstack">Fullstack</option>
<option value="other">Others</option>

    </select>
                  </div>

                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp text-nowrap">
                    <input
                      className="inputcomp"
                      type="number"
                      name="howManyMember"
                      value={formData.howManyMember}
                      onChange={handleChange}
                      maxLength="1000"
                     
                      required
                    />
                    <label className="labelcomp" htmlFor="howManyMember">How Many Members</label>
                  </div>

                  </div>
                  
                  

                  <div className="row">

                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
                    <input
                      className="teamcomp"
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleChange}
                      data-placeholder="Start Date"
                      required
                    />
                    
                  </div>
                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
                    <input
                      className="teamcomp"
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleChange}
                      data-placeholder="End Date"
                      required
                    />
                    
                  </div >

                    
                  </div>

                  <div className="row">

                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
                    <select
                      className="teamselectcomp"
                      name="meetingArrangement"
                      value={formData.meetingArrangement}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>Preference</option>
                      <option value="online">Online</option>
                      <option value="offline">Offline</option>
                    </select>
                  
                  </div>

                  <div className="row">
                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp"style={{ marginBottom: '100px', marginTop:'-80px' }}>
                  <textarea
                    className="textareacomp"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <label className="labeltxtcomp" htmlFor="message">Message</label>
                </div>

                <div className="form-group button comp">
                    <button type='submit'>SUBMIT</button>
                </div>

              </div>  
              


                  </div>
                  
                </div>
              )}

              {formData.selectValue === 'meeting' && (
                <div >


                      <div className="row">

                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp"  style={{marginBottom:'30px'}} >
                    <input
                      className="teamcomp"
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleChange}
                      data-placeholder="Start Date"
                      required
                    />
                    
                  </div>
                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
                    <input
                      className="teamcomp"
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleChange}
                      data-placeholder="End Date"
                      required
                    />
                    
                  </div >

                    
                  </div>



                  <div className="row">

                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp">
                    <input
                      className="teamcomp"
                      type="time"
                      name="whatTime"
                      value={formData.whatTime}
                      onChange={handleChange}
                      data-placeholder="what time"
                    />
                    
                  </div>

                  <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp" >
                  <select
                      className="teamselectcomp2"
                      name="meetingArrangement"
                      value={formData.meetingArrangement}
                      onChange={handleChange}
                    >
                      <option value="" disabled>Preference</option>
                      <option value="online">Online</option>
                      <option value="offline">Offline</option>
                    </select>
                  
                  </div>

                  

                  </div>
                  
                <div className="row">
                <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 comp"style={{ marginBottom: '100px', marginTop:'30px' }}>
                  <textarea
                    className="textareacomp"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <label className="labeltxtcomp" htmlFor="message">Message</label>
                </div>

                <div className="form-group button comp">
                    <button type='submit'>SUBMIT</button>
                </div>
                  
                  
                  
                </div>

                
              </div>
              )}

            
            </div>
          </form>

                       
          <div className="scroll-down1 ">
  

  <span ></span>
   <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  
  
</div>  
<h1>scroll up</h1>

        </div>
      
      </div>

                   

    </div>

    
  );
}

export default Comp;