
import './final.css'

import React, { useEffect, useState } from 'react';

function Final({ setShowModel }) {
  const COOLDOWN_PERIOD = 30 * 60 * 60 * 1000; // Cooldown period in milliseconds (24 hours)
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const storedTimestamp = localStorage.getItem('formSubmittedTimestamp');
    if (storedTimestamp) {
      const lastSubmittedTime = parseInt(storedTimestamp, 10);
      const now = Date.now();

      if (now - lastSubmittedTime < COOLDOWN_PERIOD) {
        setFormSubmitted(true);
        setTimeRemaining(COOLDOWN_PERIOD - (now - lastSubmittedTime));
      } else {
        localStorage.removeItem('formSubmittedTimestamp');
      }
    }
  }, []);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          const newTime = prevTime - 1000;
          if (newTime <= 0) {
            clearInterval(timer);
            localStorage.removeItem('formSubmittedTimestamp');
            setFormSubmitted(false);
            return 0;
          }
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeRemaining]);

  const [formData, setFormData] = useState({
    teamName: '',
    leaderName: '',
    projectTitle: '',
    projectName: '', 
    mobileNumber: '',
    gmail: '',
    college: '',
    groupOrSolo: '',
    solutionStatement: '',
    whatToDo: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://afot.in:5000/submit-form1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error submitting form', error);
    }

    const timestamp = Date.now();
    localStorage.setItem('formSubmittedTimestamp', timestamp.toString());
    setFormSubmitted(true);
    setTimeRemaining(COOLDOWN_PERIOD);

    // Show the modal after a short delay
    const timeout = setTimeout(() => {
      setShowModel(true);
    }, 500); // 500 milliseconds delay

    return () => clearTimeout(timeout);
  };

  const handleDropdownChange = (e) => {
    setFormData({ ...formData, projectTitle: e.target.value });
  };

  return (

    <div className="allpa">
        <div className="all">
      <div className="head-final text-nowrap">PROJECT PROPOSAL FORM</div>
      <div className="container cont">
        <div className="body-final">
          {formSubmitted ? (
            <p>You have already submitted the form. Please try again later.</p>
          ) : (
            <form className="form" onSubmit={handleSubmit}>
              <div className="row final-project">           
                <div className="form-group col-lg-2 col-md-2 col-sm-1 col-1 final">
                  <input className='inputfi' type="text" id='tname' name="teamName" value={formData.teamName} onChange={handleChange} required />
                  <label className='labelfi' htmlFor="tname">Team Name</label>
                </div>
                <div className="form-group col-lg-2 col-md-2 col-1 col-sm-1  final">
                  <input className='inputfi' type="text" id='lname' name="leaderName" value={formData.leaderName} onChange={handleChange} required />
                  <label className='labelfi' htmlFor="lname">Leader Name</label>
                </div>
                <div className="form-group col-lg-2 col-md-2 col-1 col-sm-1  final">
                  <select className='selectfinal' name="projectTitle" value={formData.projectTitle} onChange={handleDropdownChange} required>
                    <option value='' disabled >PROJECT</option>
                    <option value="miniproject">mini project</option>
                    <option value="softwaredevelopment">software development</option>
                    <option value="finalyearproject">final year project</option>
                  </select>
                </div>
                <div className="form-group col-lg-2 col-md-2 col-sm-1  col-1 final">
                  <input className='inputfi' type="tel" id='num' name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} maxLength="10" pattern="[0-9]{1,10}" title="Please enter a valid mobile number (up to 10 digits)" required />
                  <label className='labelfi text-nowrap' htmlFor="num">Mobile Number</label>
                </div>
                <div className="form-group col-lg-2 col-md-2 col-sm-1  col-1 final">
                  <input className='inputfi' type="email" id='ename' name="gmail" value={formData.gmail} onChange={handleChange} required />
                  <label className='labelfi' htmlFor="ename">Gmail</label>
                </div>
                <div className="form-group col-lg-2 col-md-2 col-sm-1  col-1 final">
                  <input className='inputfi' type="text" id='cname' name="college" value={formData.college} onChange={handleChange} required />
                  <label className='labelfi' htmlFor="cname">College/University/Institute</label>
                </div>
                <div className="form-group col-lg-2 col-md-2 col-sm-1  col-1 final">
                  <input className='inputfi' type="text" id='pname' name="projectName" value={formData.projectName} onChange={handleChange} required />
                  <label className='labelfi' htmlFor="pname">Project Name</label>
                </div>
                <div className="form-group  col-md-2 col-sm-1  col-1 final">
                  <input className='gbutton' type="radio" id='rbutton' name="groupOrSolo" value="group" onChange={handleChange} />
                  <label className='radio-button' htmlFor="rbutton">Group</label>
                </div>
                <div className="form-group  col-1 final">
                  <input className='rbutton' type="radio" id='gbutton' name="groupOrSolo" value="solo" onChange={handleChange} />
                  <label className='radio-rbutton' htmlFor="gbutton">Solo</label>
                </div>
                <div className="form-group textarea final">
                  <textarea className='textarea-project' id="taname" cols="30" rows="10" name="solutionStatement" value={formData.solutionStatement} onChange={handleChange} required />
                  <label className='labelftxt text-nowrap' htmlFor="taname">Solution Statement</label>
                </div>
                <div className="form-group textarea final">
                  <textarea className='textarea-project' id="saname" cols="30" rows="10" name="whatToDo" value={formData.whatToDo} onChange={handleChange} required />
                  <label className='labelftxt text-nowrap' htmlFor="saname">What Should We Do</label>
                </div>
                <div className="form-group button final">
                  <button type='submit'>SUBMIT</button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
    </div>
   
  );
}

export default Final;
