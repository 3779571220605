import React, { useEffect, useState } from 'react';
import './hard.css';

export default function Hardware({ setShowModel }) {
  const COOLDOWN_PERIOD = 30 * 60 * 60 * 1000; // Cooldown period in milliseconds (30 hours)
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const storedTimestamp = localStorage.getItem('formSubmittedTimestamp');
    if (storedTimestamp) {
      const lastSubmittedTime = parseInt(storedTimestamp, 10);
      const now = Date.now();

      if (now - lastSubmittedTime < COOLDOWN_PERIOD) {
        setFormSubmitted(true);
        setTimeRemaining(COOLDOWN_PERIOD - (now - lastSubmittedTime));
      } else {
        localStorage.removeItem('formSubmittedTimestamp');
      }
    }
  }, []);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          const newTime = prevTime - 1000;
          if (newTime <= 0) {
            clearInterval(timer);
            localStorage.removeItem('formSubmittedTimestamp');
            setFormSubmitted(false);
            return 0;
          }
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeRemaining]);

  const [formData, setFormData] = useState({
    Name: '',
    choose: '',
    components: '',
    projectName: '',
    mobileNumber: '',
    gmail: '',
    groupOrSolo: '',
    solutionStatement: '',
    whatToDo: '',
    repair: '',
    buildhardware: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://afot.in:5000/submit-form6', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      // Display a success message or redirect the user as needed
    } catch (error) {
      console.error('Error submitting form', error);
    }
    const timestamp = Date.now();
    localStorage.setItem('formSubmittedTimestamp', timestamp.toString());
    setFormSubmitted(true);
    setTimeRemaining(COOLDOWN_PERIOD);

    // Show the modal after a short delay
    const timeout = setTimeout(() => {
      setShowModel(true);
    }, 500); // 500 milliseconds delay

    return () => clearTimeout(timeout);
  };

  const handleDropdownChange = (e) => {
    setFormData({ ...formData, choose: e.target.value });
  };

  return (
    <div className="allpa">
      <div className="allhard">
        <div className="head-hard text-nowrap">PROJECT PROPOSAL FORM</div>
        <div className="container cont-hard">
          <div className="body-hard">
            {formSubmitted ? (
              <p>You have already submitted the form. Please try again later.</p>
            ) : (
              <form className="form" onSubmit={handleSubmit}>
                <div className="row hard-project">
                  <div className="form-group col-md-2 col-sm-1 col-1 hard">
                    <input className="inputhi" type="text" id="name" name="Name" value={formData.Name} onChange={handleChange} required />
                    <label className="labelhi" htmlFor="name">Name</label>
                  </div>

                  <div className="form-group col-sm-1 col-md-2 col-1 hard">
                    <input className="inputhi" type="text" id="pname" name="projectName" value={formData.projectName} onChange={handleChange} required />
                    <label className="labelhi" htmlFor="pname">Project Name</label>
                  </div>

                  <div className="form-group col-sm-1 col-md-2 col-1 hard">
                    <input className="inputhi" type="email" id="ename" name="gmail" value={formData.gmail} onChange={handleChange} required />
                    <label className="labelhi" htmlFor="ename">Gmail</label>
                  </div>

                  <div className="form-group col-sm-1 col-md-2 col-1 hard">
                    <input className="inputhi" type="tel" id="num" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} maxLength="10" pattern="[0-9]{10}" title="Please enter a valid mobile number (10 digits)" required />
                    <label className="labelhi text-nowrap" htmlFor="num">Mobile Number</label>
                  </div>

                  <div className="form-group col-sm-1 col-md-2 col-1 hard">
                    <select className="selecthard" name="choose" value={formData.choose} onChange={handleDropdownChange} required>
                      <option value="" disabled>Choose</option>
                      <option value="repair">Repair</option>
                      <option value="build hardware">Build Hardware</option>
                    </select>
                  </div>

                  <div className="form-group col-sm-1 col-md-2 col-1 hard">
                    <textarea className="inputhack" id="components" name="components" value={formData.components} onChange={handleChange} required />
                    <label className="labelhack" htmlFor="components">Components</label>
                  </div>

                  <div className="form-group col-sm-1 col-md-2 col-1 hard">
                    <input className="rbutton" type="radio" id="group" name="groupOrSolo" value="group" onChange={handleChange} />
                    <label className="radio-button" htmlFor="group">Group</label>
                  </div>

                  <div className="form-group col-sm-1 col-md-2 col-1 hard">
                    <input className="sbutton" type="radio" id="solo" name="groupOrSolo" value="solo" onChange={handleChange} />
                    <label className="radio-sbutton" htmlFor="solo">Solo</label>
                  </div>

                  <div className="form-group textarea hard">
                    <textarea className="textarea-project" id="solutionStatement" name="solutionStatement" value={formData.solutionStatement} onChange={handleChange} required />
                    <label className="labelftxt text-nowrap" htmlFor="solutionStatement">Solution Statement</label>
                  </div>

                  <div className="form-group textarea hard">
                    <textarea className="textarea-project" id="whatToDo" name="whatToDo" value={formData.whatToDo} onChange={handleChange} required />
                    <label className="labelftxt text-nowrap" htmlFor="whatToDo">What Should Do</label>
                  </div>

                  <div className="form-group button hard">
                    <button type="submit">SUBMIT</button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

